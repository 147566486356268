import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { appSpecialRoutes } from '../../constants/app-routes.const';
import { AppRouteIdentifier } from '../../enums/app-route-identifier.enum';
import { ApplicationStatus } from '../../enums/application-status.enum';
import { ApplicationType } from '../../enums/application-type.enum';
import { BusinessEntityLinkType } from '../../enums/business-entity-link-type.enum';
import { BusinessEntityType } from '../../enums/business-entity-type.enum';
import { EntityType } from '../../enums/entity-type.enum';
import { PermissionModule, RightType } from '../../enums/permission-module.enum';
import { TabsType } from '../../enums/tabs-type.enum';
import { TaskEntityTypeId } from '../../enums/task-entity-type.enum';
import { Tab } from '../../models/tab.model';
import { PermissionService } from '../communication/permission.service';
import { TabsService } from '../tabs.service';
import { UrlHelperService } from './url-helper.service';

@Injectable({
	providedIn: 'root',
})
export class EntityUrlHelperService {
	constructor(
		private permissionService: PermissionService,
		private tabsService: TabsService,
		private urlHelperService: UrlHelperService,
		private router: Router
	) {}

	getEntityUrl(type: EntityType, id?: number | string, permissionId?: number): string | null {
		switch (type) {
			case EntityType.Company:
			case EntityType.Manufacturer:
				return this.getEntityUrlByTabType(TabsType.CompanyInfo, id, permissionId);
			case EntityType.Facility:
				return this.getEntityUrlByTabType(TabsType.FacilityInfo, id, permissionId);
			case EntityType.PrivateLabeler:
				return this.getEntityUrlByTabType(TabsType.PrivateLabelerInfo, id, permissionId);
			case EntityType.Vendor:
				return this.getEntityUrlByTabType(TabsType.VendorInfo, id, permissionId);
			case EntityType.Agency:
				return this.getEntityUrlByTabType(TabsType.AgencyInfo, id, permissionId);
			case EntityType.CustomerIngredient:
			case EntityType.IngredientInfo:
				return this.getEntityUrlByTabType(TabsType.CustomerIngredientInfo, id, permissionId);
			case EntityType.BatchIngredient:
				return this.getEntityUrlByTabType(TabsType.IngredientBatchInfo, id, permissionId);
			case EntityType.BatchIngredientItem:
				return this.getIngredientBatchItemRoute(PermissionModule.IngredientBatch, id);
			case EntityType.MasterIngredient:
				return this.getEntityUrlByTabType(TabsType.MasterIngredientInfo, id, permissionId);
			case EntityType.ContactDetail:
				return this.getEntityUrlByTabType(TabsType.ContactInfo, id, permissionId);
			case EntityType.Visit:
				return this.getEntityUrlByTabType(TabsType.VisitsInfo, id, permissionId);
			case EntityType.Trip:
				return this.getEntityUrlByTabType(TabsType.TripsInfo, id, permissionId);
			case EntityType.Amex:
				return this.getRouteIfAllowed('amex/:id', PermissionModule.Amex, true, id);
			case EntityType.Task:
				return this.getRouteIfAllowed('tasks/:id', PermissionModule.Task, true, id);
			case EntityType.User:
				return this.getEntityUrlByTabType(TabsType.UserSettingsInfo, id, permissionId);
			case EntityType.CodeChangeUtility:
				const codeChangeUtilityUrl = '/ingredients/code-change-utility/:id';
				return this.getRouteIfAllowed(codeChangeUtilityUrl, PermissionModule.CodeChangeUtility, false, id);
			default:
				return null;
		}
	}

	getBusinessEntityUrl(type: BusinessEntityType, id?: number): string | null {
		switch (type) {
			case BusinessEntityType.Company:
			case BusinessEntityType.Manufacturer:
				return this.getEntityUrlByTabType(TabsType.CompanyInfo, id);
			case BusinessEntityType.Facility:
				return this.getEntityUrlByTabType(TabsType.FacilityInfo, id);
			case BusinessEntityType.PrivateLabeler:
				return this.getEntityUrlByTabType(TabsType.PrivateLabelerInfo, id);
			case BusinessEntityType.Vendor:
				return this.getEntityUrlByTabType(TabsType.VendorInfo, id);
			case BusinessEntityType.Agency:
				return this.getEntityUrlByTabType(TabsType.AgencyInfo, id);
			default:
				return null;
		}
	}

	getBusinessentityLinkUrl(type: BusinessEntityLinkType, id?: number): string | null {
		switch (type) {
			case BusinessEntityLinkType.Company:
			case BusinessEntityLinkType.Manufacturer:
				return this.getEntityUrlByTabType(TabsType.CompanyInfo, id);
			case BusinessEntityLinkType.Facility:
				return this.getEntityUrlByTabType(TabsType.FacilityInfo, id);
			case BusinessEntityLinkType.PrivateLabeler:
				return this.getEntityUrlByTabType(TabsType.PrivateLabelerInfo, id);
			case BusinessEntityLinkType.Vendor:
				return this.getEntityUrlByTabType(TabsType.VendorInfo, id);
			case BusinessEntityLinkType.Agency:
				return this.getEntityUrlByTabType(TabsType.AgencyInfo, id);
			case BusinessEntityLinkType.Contact:
				return this.getEntityUrlByTabType(TabsType.ContactInfo, id);
			default:
				return null;
		}
	}

	getApplicationUrlByType(type: ApplicationType, id?: number): string | null {
		return null;
	}

	getApplicationUrlByTypeAndStatus(id: number, status: ApplicationStatus, type: ApplicationType) {
		if (status === ApplicationStatus.Prospect) {
			if (this.permissionService.canView([PermissionModule.Application], true)) {
				return `/applications/merge/${id}/${type}`;
			}
			return null;
		} else {
			return this.getApplicationUrlByType(type, id);
		}
	}

	getTaskEntityUrl(
		taskEntityTypeId: TaskEntityTypeId,
		id: number,
		applicationTypeId?: ApplicationType,
		applicationStatusId?: ApplicationStatus
	): string | null {
		switch (taskEntityTypeId) {
			case TaskEntityTypeId.Visit:
				return this.getEntityUrlByTabType(TabsType.VisitsInfo, id);
			case TaskEntityTypeId.Trip:
				return this.getEntityUrlByTabType(TabsType.TripsInfo, id);
			case TaskEntityTypeId.IngredientInfo:
				return this.getEntityUrlByTabType(TabsType.CustomerIngredientInfo, id);
			case TaskEntityTypeId.BatchIngredient:
				return `/ingredients/ingredient-batch/${id}`;
			case TaskEntityTypeId.K_ID:
				return `/master-ingredient/${id}`;
			case TaskEntityTypeId.ContactDetail:
			case TaskEntityTypeId.Person:
				return this.getEntityUrlByTabType(TabsType.ContactInfo, id);
			case TaskEntityTypeId.Application:
				return this.getApplicationUrlByTypeAndStatus(id, applicationStatusId, applicationTypeId);
		}
	}

	getEntityUrlByTabType(type: TabsType, entityId?: number | string, permissionId?: number): string | null {
		if (this.permissionService.isAnyTabViewable(type)) {
			const tabs = this.tabsService.getTabs(type, entityId);
			let viewableTab: Tab;
			if (permissionId) {
				viewableTab = tabs.find(
					tab =>
						permissionId === tab.permissionId &&
						this.permissionService.canView([tab.permissionId], tab.isModule)
				);
			} else {
				viewableTab = tabs.find(tab => this.permissionService.canView([tab.permissionId], tab.isModule));
			}
			if (entityId) {
				return this.urlHelperService.replaceIdInUrl(viewableTab.path, entityId);
			} else {
				return viewableTab.path;
			}
		}
		return null;
	}

	getEntityActivateUrl(type: EntityType, id?: number) {
		switch (type) {
			case EntityType.Company:
				return `/companies/${id}/activate`;
			case EntityType.Facility:
				return `/facilities/${id}/activate`;
			default:
				return null;
		}
	}

	getEntityInactivateUrl(type: EntityType, id?: number) {
		switch (type) {
			case EntityType.Company:
				return `/companies/${id}/inactivate`;
			case EntityType.Facility:
				return `/facilities/${id}/inactivate`;
			default:
				return null;
		}
	}

	getByIdentifier(routeId: AppRouteIdentifier, entityId?: number) {
		const found = appSpecialRoutes[routeId];
		let url = null;
		switch (found.permissionType) {
			case RightType.View:
				url = this.getRouteIfAllowed(found.path, found.permissionId, found.isModule, entityId);
				break;
			case RightType.Update:
				const canUpdate = this.permissionService.canUpdatePermission[found.permissionId];
				if (canUpdate) {
					url = this.getRouteIfAllowed(found.path, found.permissionId, found.isModule, entityId);
				}
				break;
			case RightType.Create:
				const canCreate = this.permissionService.canCreatePermission[found.permissionId];
				if (canCreate) {
					url = this.getRouteIfAllowed(found.path, found.permissionId, found.isModule, entityId);
				}
				break;
		}
		return url;
	}

	navigateByIdentifier(routeId: AppRouteIdentifier, returnUrl = false, entityId?: number) {
		const found = appSpecialRoutes[routeId];
		if (found) {
			let url = null;
			switch (found.permissionType) {
				case RightType.View:
					url = this.getRouteIfAllowed(found.path, found.permissionId, found.isModule, entityId);
					break;
				case RightType.Update:
					const canUpdate = this.permissionService.canUpdatePermission[found.permissionId];
					if (canUpdate) {
						url = this.getRouteIfAllowed(found.path, found.permissionId, found.isModule, entityId);
					}
					break;
				case RightType.Create:
					const canCreate = this.permissionService.canCreatePermission[found.permissionId];
					if (canCreate) {
						url = this.getRouteIfAllowed(found.path, found.permissionId, found.isModule, entityId);
					}
					break;
			}

			if (url) {
				this.router.navigate([url]);
			}
		}
	}

	private getRouteIfAllowed(
		route: string,
		permissionId: number,
		isModule: boolean,
		id?: number | string
	): string | null {
		const canView = this.permissionService.canView([permissionId], isModule);
		if (canView) {
			if (id) {
				return this.urlHelperService.replaceIdInUrl(route, id);
			}
			return route;
		}
		return null;
	}

	private getIngredientBatchItemRoute(permissionId: number, id?: number | string): string | null {
		const canView = this.permissionService.canView([permissionId], true);
		if (canView) {
			return this.router.url + '/' + id;
		}
		return null;
	}
}
