export const currentUserSettingInfoTabs = [
	{
		name: 'Info',
		path: `/my-account`,
	},
	{
		name: 'Security',
		path: `/my-account/security`,
	},
	{
		name: 'Restrictions',
		path: `/my-account/restrictions`,
	},
	{
		name: 'Tasks',
		path: `/my-account/tasks`,
	},
];
