<div class="header-container">
	<span>
		{{ params.displayName }}
	</span>
	<div *ngIf="params.column?.colDef?.allowClick && allowSelection" class="checkbox">
		<app-checkbox [(model)]="selected" (modelChange)="onCheck($event)" [roundCheckbox]="true"></app-checkbox>
	</div>
	<div *ngIf="params?.api?.selectedRows?.length > 0" class="m-l-5">
		<app-icon-view
			(click)="onViewSelection()"
			iconCls="icon--secondary icon--no-align"
			matTooltip="View Selection"
		></app-icon-view>
	</div>
</div>
