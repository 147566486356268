import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../../enums/local-storage-keys.enum';
import { SelectedRenewalCompany } from '../../interfaces/selected-renewal-company-list.interface';
import { LoginResponse } from '../../models/auth/login.response';
import ls from 'localstorage-slim';
import { AdvancedPermission, Permission } from '../../models/permission.model';
import { UserInfo } from '../../models/auth/user.model';
import { AccordionIdentifier } from '../../enums/accordion-identifier.enum';
import { ProductReviewIdPair } from '../../models/products/product-review-id-pair.model';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor() {}

	set magicReviewPageIds(ids: number[]) {
		this.generalSetter(ids, LocalStorageKeys.ADMIN_MAGIC_REVIEW_PAGE_IDS);
	}
	get magicReviewPageIds(): number[] {
		return this.generalGetter(LocalStorageKeys.ADMIN_MAGIC_REVIEW_PAGE_IDS);
	}

	set productReviewIds(ids: ProductReviewIdPair[]) {
		this.generalSetter(ids, LocalStorageKeys.ADMIN_PRODUCT_REVIEW_PAGE_IDS);
	}
	get productReviewIds(): ProductReviewIdPair[] {
		return this.generalGetter(LocalStorageKeys.ADMIN_PRODUCT_REVIEW_PAGE_IDS);
	}

	set renewalPageCompanies(ids: SelectedRenewalCompany[]) {
		this.generalSetter(ids, LocalStorageKeys.ADMIN_RENEWAL_PAGE_IDS);
	}
	get renewalPageCompanies(): SelectedRenewalCompany[] {
		return this.generalGetter(LocalStorageKeys.ADMIN_RENEWAL_PAGE_IDS);
	}

	set loginData(value: LoginResponse) {
		this.generalSetter(value, LocalStorageKeys.LOGIN_DATA_STORAGE_KEY);
	}
	get loginData(): LoginResponse {
		const value = this.generalGetter(LocalStorageKeys.LOGIN_DATA_STORAGE_KEY);
		if (value) {
			return new LoginResponse(value);
		}
		return null;
	}

	set userPermissions(value: Permission[]) {
		this.generalSetter(value, LocalStorageKeys.USER_PERMISSIONS);
	}
	get userPermissions(): Permission[] {
		return this.generalGetter(LocalStorageKeys.USER_PERMISSIONS);
	}

	set userAdvancedPermissions(value: AdvancedPermission[]) {
		this.generalSetter(value, LocalStorageKeys.USER_ADVANCED_PERMISSIONS);
	}
	get userAdvancedPermissions(): AdvancedPermission[] {
		return this.generalGetter(LocalStorageKeys.USER_ADVANCED_PERMISSIONS);
	}

	set userId(value: number) {
		this.generalSetter(value, LocalStorageKeys.USER_ID_KEY);
	}
	get userId(): number {
		return this.generalGetter(LocalStorageKeys.USER_ID_KEY);
	}

	set currentUser(value: UserInfo) {
		this.generalSetter(value, LocalStorageKeys.CURRENT_USER);
	}
	get currentUser(): UserInfo {
		return this.generalGetter(LocalStorageKeys.CURRENT_USER);
	}

	set idle(value: boolean) {
		this.generalSetter(value, LocalStorageKeys.USER_IDLE);
	}
	get idle(): boolean {
		return this.generalGetter(LocalStorageKeys.USER_IDLE);
	}

	set lastTabClosedAt(value: string) {
		this.generalSetter(value, LocalStorageKeys.LAST_TAB_CLOSED_AT);
	}
	get lastTabClosedAt(): string {
		return this.generalGetter(LocalStorageKeys.LAST_TAB_CLOSED_AT);
	}

	set accordions(value: { [id: number]: boolean }) {
		this.generalSetter(value, LocalStorageKeys.USER_ACCORDIONS_STATUS);
	}
	get accordions(): { [id: number]: boolean } {
		const value = this.generalGetter(LocalStorageKeys.USER_ACCORDIONS_STATUS);
		if (value) {
			return value;
		}
		return {};
	}

	set updateMasterIngredientIds(ids: number[]) {
		this.generalSetter(ids, LocalStorageKeys.UPDATE_MASTER_INGREDIENT_IDS);
	}
	get updateMasterIngredientIds(): number[] {
		return this.generalGetter(LocalStorageKeys.UPDATE_MASTER_INGREDIENT_IDS);
	}

	clearLocalStorage() {
		this.cleanAccordionStatuses();
		this.renewalPageCompanies = null;
		this.magicReviewPageIds = null;
		this.productReviewIds = null;
		this.loginData = null;
		this.userId = null;
		this.userPermissions = null;
		this.currentUser = null;
		this.lastTabClosedAt = null;
		this.updateMasterIngredientIds = null;
	}

	private generalSetter(value: any, key: LocalStorageKeys) {
		if (value !== null && value !== undefined) {
			ls.set(key, value, { encrypt: true });
		} else {
			ls.remove(key);
		}
	}

	private generalGetter(key: LocalStorageKeys): any {
		const value = ls.get(key, { decrypt: true });
		if (value !== undefined) {
			return value;
		}
		return null;
	}

	private cleanAccordionStatuses() {
		const keysToRemove = [];
		const value = this.accordions;
		Object.keys(value).forEach(key => {
			if (AccordionIdentifier[key] === undefined) {
				keysToRemove.push(key);
			}
		});

		keysToRemove.forEach(key => {
			delete value[key];
		});
		this.accordions = value;
	}
}
