import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDataService } from '../interfaces/i-data-service.interface';
import { SearchPaging } from '../models/general/search-paging.model';
import { GridType } from '../enums/grid-type.enum';
import { Observable } from 'rxjs';
import { BatchIngredientSummary } from '../models/ingredients/batch-ingredients-summary.model';
import { BatchIngredientItem } from '../models/ingredients/batch-ingredient.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { PackageValidation } from '../models/package-validation.model';

@Injectable({
	providedIn: 'root',
})
export class BatchIngredientService implements IDataService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	search(request: SearchPaging, filters: any, gridType: GridType) {
		const requestModel = {
			model: request,
			criteria: {
				keyword: filters.keyword,
				filterDropdown: filters.filterDropdown,
			},
			template: gridType,
		};
		return this.http.post(
			`${this.appConfigurationService.api.product}/IngredientBatches/Items/Search`,
			requestModel
		);
	}

	exportSearch(request: SearchPaging, filters: any, gridType: GridType) {}

	editMultiple(data: { ingredientBatchItemIds: number[]; ingredientApprovalId: number; evaluatedById: number }) {
		return this.http.post(`${this.appConfigurationService.api.product}/IngredientBatches/Items/SaveMultiple`, data);
	}

	getBatchIngredientsSummary(batchIngredientId: number): Observable<BatchIngredientSummary> {
		return this.http.get<BatchIngredientSummary>(
			`${this.appConfigurationService.api.service}/Summary/batchIngredient?id=${batchIngredientId}`
		);
	}

	loadBatchIngredients(batchIngredientIds: number[]): Observable<BatchIngredientItem[]> {
		return this.http.post<BatchIngredientItem[]>(
			`${this.appConfigurationService.api.product}/IngredientBatches/items/load`,
			batchIngredientIds
		);
	}

	load(id) {
		return this.http.get<BatchIngredientItem>(
			`${this.appConfigurationService.api.service}/IngredientBatch/item/${id}/load`
		);
	}

	loadMultiple(ids: number[]): Observable<BatchIngredientItem[]> {
		return this.http.post<BatchIngredientItem[]>(
			`${this.appConfigurationService.api.service}/IngredientBatch/item/loadByIds`,
			ids
		);
	}

	saveBatchItem(
		batchId: number,
		item: BatchIngredientItem,
		keepFacilitiesTheSame?: boolean
	): Observable<BatchIngredientItem> {
		return this.http.post<BatchIngredientItem>(
			`${this.appConfigurationService.api.service}/IngredientBatch/item/save?keepFacilitiesTheSame=${keepFacilitiesTheSame}`,
			{
				batchId,
				item,
			}
		);
	}

	saveBatchItems(
		batchId: number,
		items: BatchIngredientItem[],
		keepFacilitiesTheSame: boolean
	): Observable<BatchIngredientItem[]> {
		return this.http.post<BatchIngredientItem[]>(
			`${this.appConfigurationService.api.service}/IngredientBatch/items/save?batchId=${batchId}&keepFacilitiesTheSame=${keepFacilitiesTheSame}`,
			items
		);
	}

	removeItems(batchHeaderId: number, ingredientBatchItemIds: number[]) {
		return this.http.post(
			`${this.appConfigurationService.api.product}/IngredientBatches/Items/Delete?batchHeaderId=${batchHeaderId}`,
			ingredientBatchItemIds
		);
	}

	batchIngredientItemLoad(ingredientBatchItemId: number) {
		return this.http.post<any>(
			`${this.appConfigurationService.api.product}/IngredientBatches/IngredientItem/Load?ingredientBatchItemId=${ingredientBatchItemId}`,
			{}
		);
	}

	batchIngredientItemsLoad(ingredientBatchItemsIds: number[]) {
		return this.http.post<any[]>(
			`${this.appConfigurationService.api.product}/IngredientBatches/IngredientItems/Load?ingredientBatchItemIds=${ingredientBatchItemsIds}`,
			{}
		);
	}

	sendForReview(batchHeaderId: number): Observable<any> {
		return this.http.post<any>(
			`${this.appConfigurationService.api.product}/IngredientBatches/review/sendForReview`,
			batchHeaderId
		);
	}

	validatePackaging(packageValidation: PackageValidation): Observable<boolean> {
		return this.http.post<boolean>(
			`${this.appConfigurationService.api.product}/IngredientBatches/packagingType/validate`,
			packageValidation
		);
	}

	validateGroupAndPackaging(packageValidation: PackageValidation): Observable<boolean> {
		return this.http.post<boolean>(
			`${this.appConfigurationService.api.product}/IngredientBatches/packagingType/validateEvaluationGroup`,
			packageValidation
		);
	}
}
