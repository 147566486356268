import { Pipe, PipeTransform } from '@angular/core';
import { DynamicMenuOption } from '../../core/models/dynamic-menu-option.model';

@Pipe({
	name: 'option',
	pure: false,
})
export class OptionPipe implements PipeTransform {
	transform(item: DynamicMenuOption): DynamicMenuOption {
		if (item?.alternativeOptionId && item?.alternativeOptions?.length > 0) {
			return item.alternativeOptions.find(i => +i.id === +item.alternativeOptionId);
		}
		return item;
	}
}
