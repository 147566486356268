import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
	exports: [
		MatTabsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		DragDropModule,
		MatTooltipModule,
		MatMenuModule,
	],
})
export class MaterialModule {}
