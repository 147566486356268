<section class="row">
	<section class="col branding"></section>
	<section class="col content" [class.loading]="pageLoading">
		<div class="confirm-account">
			<img src="../../../../assets/images/logo-dark.png" class="logo" />
			<div class="welcome-text" [class.welcome-text--status]="!isRegistrationCodeAvailable">
				{{
					isRegistrationCodeAvailable
						? 'Welcome! Please setup the password for your account.'
						: 'Invitation to OK Kosher'
				}}
			</div>
			<div *ngIf="isRegistrationCodeAvailable" class="form form--space-lg" [formGroup]="confirmForm">
				<div class="form__element form__element--full-width m-b-35">
					<app-text-input
						placeholder="Password"
						label="Password"
						inputCls="text-input--block"
						size="lg"
						[field]="form.password"
						[type]="InputType.Password"
						[showInfoTooltip]="true"
					>
					</app-text-input>
				</div>
				<div class="form__element form__element--full-width m-b-35">
					<app-text-input
						placeholder="Confirm Password"
						label="Confirm Password"
						inputCls="text-input--block"
						size="lg"
						[field]="form.confirmPassword"
						[type]="InputType.Password"
					></app-text-input>
				</div>
				<div class="form__element form__element--full-width">
					<button
						(click)="confirm()"
						class="btn btn--dark btn--lg"
						[ngClass]="{
							loading: buttonLoading
						}"
					>
						Confirm
					</button>
				</div>
			</div>
			<div *ngIf="!isRegistrationCodeAvailable" class="expired-status">
				<div class="expired-status__info">
					<span>The invitation link is no longer active.</span>
				</div>
				<span class="expired-status__support"
					>Please request a new link from an administrator or contact the IT Support team at
					<a href="mailto:it@ok.org">it@ok.org</a>.</span
				>
			</div>
		</div>
		<div class="footer-links">
			<a href="#" class="link text text--light">Terms of use</a>&nbsp;
			<a href="#" class="link text text--light">Privacy policy</a>
		</div>
	</section>
</section>
