import { PrivateLabelerModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const privateLabelerInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/private-label/:id`,
		permissionId: PrivateLabelerModuleTabs.Info,
	},
	{
		name: 'Contacts',
		path: `/private-label/:id/contacts`,
		permissionId: PrivateLabelerModuleTabs.Contacts,
	},
	{
		name: 'Companies',
		path: `/private-label/:id/companies`,
		permissionId: PrivateLabelerModuleTabs.Companies,
	},
	{
		name: 'PL Contract',
		path: `/private-label/:id/pl-contract`,
		permissionId: PrivateLabelerModuleTabs.PLContract,
	},
	{
		name: 'Alias',
		path: `/private-label/:id/alias`,
		permissionId: PrivateLabelerModuleTabs.Alias,
	},
	{
		name: 'Brands',
		path: `/private-label/:id/brands`,
		permissionId: PrivateLabelerModuleTabs.Brands,
	},
	{
		name: 'Notes',
		path: `/private-label/:id/notes`,
		permissionId: PrivateLabelerModuleTabs.Notes,
	},
	{
		name: 'Guidelines',
		path: `/private-label/:id/guidelines`,
		permissionId: PrivateLabelerModuleTabs.Guidelines,
	},
	{
		name: 'Tasks',
		path: `/private-label/:id/tasks`,
		permissionId: PrivateLabelerModuleTabs.Tasks,
	},
	{
		name: 'Invoices',
		path: `/private-label/:id/invoices`,
		permissionId: PrivateLabelerModuleTabs.Invoices,
	},
	{
		name: 'Kosher Certificate Info',
		path: '/private-label/:id/kosher-certificate-info',
		permissionId: PrivateLabelerModuleTabs.KosherCertificateInfo,
	},
	{
		name: 'Kosher Certificates',
		path: '/private-label/:id/kosher-certificates',
		permissionId: PrivateLabelerModuleTabs.KosherCertificate,
	},
	{
		name: 'Products',
		path: '/private-label/:id/products',
		permissionId: PrivateLabelerModuleTabs.Products,
	},
	{
		name: 'Product Publication',
		path: `/private-label/:id/product-publication`,
		permissionId: PrivateLabelerModuleTabs.ProductPublication,
	},
	{
		name: 'Audit Trail',
		path: `/private-label/:id/audit-trail`,
	},
];
