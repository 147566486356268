import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, IHeaderParams } from 'ag-grid-community';

@Component({
	selector: 'app-title-checkbox-label-header-renderer',
	templateUrl: './title-checkbox-label-header-renderer.component.html',
	styleUrls: ['./title-checkbox-label-header-renderer.component.scss'],
})
export class TitleCheckboxLabelHeaderRendererComponent implements IHeaderAngularComp {
	public params: any;

	constructor() {}

	public afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

	public agInit(params: any): void {
		this.params = params;
	}

	onCheck(value) {
		this.params.isChecked = value;
		this.params.column.colDef.isChecked = value;
		this.params.api.pushHeaderCheckboxInput(this.params);
	}

	refresh(params: IHeaderParams): boolean {
		return false;
	}
}
