import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginResponse } from 'src/app/core/models/auth/login.response';
import { Observable } from 'rxjs';
import { PlatformType } from 'src/app/core/enums/platform-type.enum';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { LogoutRequest } from 'src/app/core/models/auth/logout-request.model';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	login(username: string, password: string, pin: string = null): Observable<LoginResponse> {
		const formData = new FormData();
		formData.append('username', username);
		formData.append('password', password);
		formData.append('pin', pin);
		formData.append('platform', PlatformType.CompanyPortal.toString());
		return this.http.post<LoginResponse>(`${this.appConfigurationService.api.auth}/Auth/token`, formData);
	}

	validateCurrentUser(username: string, password: string, pin: string = null): Observable<boolean> {
		const formData = new FormData();
		formData.append('username', username);
		formData.append('password', password);
		formData.append('pin', pin);
		formData.append('platform', PlatformType.CompanyPortal.toString());
		return this.http.post<boolean>(`${this.appConfigurationService.api.auth}/Auth/validate_current_user`, formData);
	}

	refreshToken(token: string) {
		return this.http.post<LoginResponse>(`${this.appConfigurationService.api.auth}/Auth/refresh_token`, {
			refreshToken: token,
			platform: PlatformType.CompanyPortal.toString(),
		});
	}

	forgotPassword(username: string): Observable<object> {
		return this.http.post(
			`${this.appConfigurationService.api.auth}/Auth/forgot_password?username=${username}&platformId=${PlatformType.CompanyPortal}`,
			null
		);
	}

	confirmAccount(confirmAccountContainer) {
		return this.http.post<LoginResponse>(
			`${this.appConfigurationService.api.security}/user/confirmAccount`,
			confirmAccountContainer
		);
	}

	checkRegistrationCode(registrationCode: string): Observable<boolean> {
		return this.http.get<boolean>(
			`${this.appConfigurationService.api.security}/user/RegistrationCode/Valid?registrationCode=${registrationCode}`
		);
	}

	logout(logoutRequest: LogoutRequest): Observable<void> {
		return this.http.post<void>(`${this.appConfigurationService.api.auth}/Auth/logout`, logoutRequest);
	}
}
