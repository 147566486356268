import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NoteFormComponent } from '../../inputs/note-form/note-form.component';
import { Keys } from '../../../core/enums/keys.enum';
import { Note } from 'src/app/core/models/note.model';

@Component({
	selector: 'app-note-cell-renderer',
	templateUrl: './note-cell-renderer.component.html',
	styleUrls: ['./note-cell-renderer.component.scss'],
})
export class NoteCellRendererComponent implements ICellRendererAngularComp {
	public params: any;
	@ViewChild(NoteFormComponent) noteComp: NoteFormComponent;

	agInit(params: any): void {
		this.params = params;
	}

	get data() {
		return this.params.data;
	}

	setLoading(value: boolean) {
		this.noteComp.isNoteLoading = value;
	}

	initComponent(entityId: number, entityType: number, notes: Note[], noteType?: number) {
		if (entityId && entityType && this.noteComp) {
			this.noteComp.entityId = entityId;
			this.noteComp.entityTypeId = entityType;
			if (noteType) {
				this.noteComp.noteTypeId = noteType;
			}
			this.noteComp.onLoadNote(notes);
		}
	}

	saveNote(entityId: number, entityType: number, noteType?: number) {
		if (entityId && entityType && this.noteComp?.hasChanges) {
			this.noteComp.entityTypeId = entityType;
			if (noteType) {
				this.noteComp.noteTypeId = noteType;
			}
			return this.noteComp.saveAsync(entityId);
		}
	}

	refresh(): boolean {
		return false;
	}

	onKeyDown(event: any): void {
		if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}

		if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event)) {
			if (event.preventDefault) event.preventDefault();
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.noteComp?.input?.inputRef?.nativeElement.focus();
		});
	}

	private getCharCodeFromEvent(event: any): any {
		event = event || window.event;
		return typeof event.which == 'undefined' ? event.keyCode : event.which;
	}

	private isCharNumeric(charStr: string): boolean {
		return !!/\d/.test(charStr);
	}

	private isKeyPressedNumeric(event: any): boolean {
		const charCode = this.getCharCodeFromEvent(event);
		const charStr = event.key ? event.key : String.fromCharCode(charCode);
		return this.isCharNumeric(charStr);
	}

	private deleteOrBackspace(event: any) {
		return [Keys.KEY_DELETE, Keys.KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private isLeftOrRight(event: any) {
		return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private finishedEditingPressed(event: any) {
		const charCode = this.getCharCodeFromEvent(event);
		return charCode === Keys.KEY_ENTER || charCode === Keys.KEY_TAB;
	}
}
