import { PermissionModule } from '../../enums/permission-module.enum';

export const customerIngredientModuleTabs = [
	{
		name: 'Ingredients',
		path: `/ingredients/customer-ingredients`,
		isModule: true,
		permissionId: PermissionModule.CustomerIngredients,
	},
	{
		name: 'Ingredient Batch',
		path: `/ingredients/ingredient-batch`,
		isModule: true,
		permissionId: PermissionModule.IngredientBatch,
	},
	{
		name: 'Review Ingredients',
		path: `/ingredients/review-ingredients`,
		isModule: true,
		permissionId: PermissionModule.ReviewIngredients,
	},
	{
		name: 'Code Change Utility',
		path: `/ingredients/code-change-utilities`,
		isModule: true,
		permissionId: PermissionModule.ReviewIngredients,
	},
];
