import { ContactModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const contactInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/contacts/:id`,
		permissionId: ContactModuleTabs.Info,
	},
	{
		name: 'Notes',
		path: `/contacts/:id/notes`,
		permissionId: ContactModuleTabs.Notes,
	},
	{
		name: 'Tasks',
		path: `/contacts/:id/tasks`,
		permissionId: ContactModuleTabs.Tasks,
	}
];
