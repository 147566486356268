import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleModule } from '@ng-idle/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import 'ag-grid-enterprise';
import { NotifierModule } from 'angular-notifier';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MomentModule } from 'ngx-moment';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';
import { catchError, map } from 'rxjs/operators';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { Observable, ObservableInput, of } from '../../node_modules/rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { Global } from './core/services/global.service';
import { AuthenticationInterceptor } from './modules/auth/auth.interceptor';
import { AuthModule } from './modules/auth/auth.module';
import { metaReducers, reducers } from './store/reducers';
import { SharedModule } from './shared/shared.module';

function load(http: HttpClient, config: AppConfigurationService): () => Promise<boolean> {
	return (): Promise<boolean> => {
		return new Promise<boolean>((resolve: (a: boolean) => void): void => {
			http.get('../app.config.json')
				.pipe(
					map((x: AppConfigurationService) => {
						config.api = x.api;
						resolve(true);
					}),
					catchError((x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
						if (x.status !== 404) {
							resolve(false);
						}
						resolve(true);
						return of({});
					})
				)
				.subscribe();
		});
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		CoreModule,
		SharedModule,
		AuthModule.forRoot(),
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false },
		}),
		EffectsModule.forRoot([]),
		!environment.production ? StoreDevtoolsModule.instrument() : [],
		SimpleModalModule.forRoot(
			{ container: document.body },
			{ ...defaultSimpleModalOptions, ...{ closeOnEscape: true, closeOnClickOutside: false } }
		),
		BrowserAnimationsModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		MomentModule,
		NgIdleModule.forRoot(),
		NotifierModule.withConfig({
			position: {
				horizontal: { position: 'middle' },
				vertical: { position: 'top' },
			},
		}),
	],
	providers: [
		HttpClient,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
		AppConfigurationService,
		{ provide: APP_INITIALIZER, useFactory: load, deps: [HttpClient, AppConfigurationService], multi: true },
		Global,
	],
	bootstrap: [AppComponent],
	exports: [NotifierModule],
})
export class AppModule {}
