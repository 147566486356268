import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FormControl } from '@angular/forms';
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_ENTER = 13;
const KEY_TAB = 9;

@Component({
	selector: 'app-dropdown-cell-renderer',
	templateUrl: './dropdown-cell-renderer.component.html',
	styleUrls: ['./dropdown-cell-renderer.component.scss'],
})
export class DropdownCellRendererComponent implements ICellRendererAngularComp {
	@ViewChild('input') input: ElementRef;
	public params;

	public selectedOption: FormControl;

	agInit(params): void {
		this.selectedOption = new FormControl(params.data.frequencyId || 0);
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}

	pushDropdownInput() {
		this.params.value = this.selectedOption.value;
		this.params.api.pushDropdownInput(this.params);
		this.params.api.isDropdownOpened(false);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.input.nativeElement?.focus();
		});
	}

	onKeyDown(event: any): void {
		if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}

		if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event)) {
			if (event.preventDefault) event.preventDefault();
		}
	}

	private getCharCodeFromEvent(event: any): any {
		event = event || window.event;
		return typeof event.which == 'undefined' ? event.keyCode : event.which;
	}

	private isCharNumeric(charStr: string): boolean {
		return !!/\d/.test(charStr);
	}

	private isKeyPressedNumeric(event: any): boolean {
		const charCode = this.getCharCodeFromEvent(event);
		const charStr = event.key ? event.key : String.fromCharCode(charCode);
		return this.isCharNumeric(charStr);
	}

	private deleteOrBackspace(event: any) {
		return [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private isLeftOrRight(event: any) {
		return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private finishedEditingPressed(event: any) {
		const charCode = this.getCharCodeFromEvent(event);
		return charCode === KEY_ENTER || charCode === KEY_TAB;
	}

	isDropdownOpened(event) {
		this.params.api.isDropdownOpened(event);
		return event;
	}
}
