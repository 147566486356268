import { FacilityModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const facilityInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/projects/:id`,
		permissionId: FacilityModuleTabs.Info,
	},
	{
		name: 'Contacts',
		path: `/projects/:id/contacts`,
		permissionId: FacilityModuleTabs.Contacts,
	},
	{
		name: 'Companies',
		path: `/projects/:id/companies`,
		permissionId: FacilityModuleTabs.Companies,
	},
	{
		name: 'Notes',
		path: `/projects/:id/notes`,
		permissionId: FacilityModuleTabs.Notes,
	},
	{
		name: 'Tasks',
		path: `/projects/:id/tasks`,
		permissionId: FacilityModuleTabs.Tasks,
	},
	{
		name: 'Instructions',
		path: `/projects/:id/instructions`,
		permissionId: FacilityModuleTabs.Instructions,
	},
	{
		name: 'Guidelines',
		path: `/projects/:id/guidelines`,
		permissionId: FacilityModuleTabs.Guidelines,
	},
];
