import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-country-cell-renderer',
	templateUrl: './country-cell-renderer.component.html',
	styleUrls: ['./country-cell-renderer.component.scss'],
})
export class CountryCellRendererComponent implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}
}
