import * as fromApplication from '../reducers/application.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectState = createFeatureSelector<fromApplication.ApplicationState>('application');

export const selectApplication = createSelector(selectState, state => state);

export const selectApplicationSummary = createSelector(selectApplication, state => state.appSummary);

export const selectApplicationSummaryLoading = createSelector(selectApplication, state => state.appSummaryLoading);

export const selectApplicationSummarySaved = createSelector(selectApplication, state => state.appSummarySaved);

export const selectApplicationPublished = createSelector(selectApplication, state => state.applicationPublished);

export const selectApplicationFacilities = createSelector(selectApplication, state => state.appFacilities);

export const selectLoadingApplicationFacilities = createSelector(selectApplication, state => state.loadingAppFacilites);

export const selectApplicationFacilitiesSaved = createSelector(selectApplication, state => state.appFacilitiesSaved);

export const selectApplicationFacilitiesSavedResult = createSelector(
	selectApplication,
	state => state.appFacilitiesSaveResult
);
