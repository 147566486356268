import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'filterBy',
	pure: false,
})
export class FilterPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(items: any[], objectProperyName: string, value: any): any {
		if (!items) {
			return [];
		}

		const c = items.filter(item => {
			if (Object.prototype.hasOwnProperty.call(item, objectProperyName)) {
				return item[objectProperyName] == value;
			} else {
				return true;
			}
		});

		return c;
	}
}
