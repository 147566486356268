<div class="row" *ngIf="label">
	<div class="col">
		<label
			class="label"
			(click)="onLabelClick()"
			[ngClass]="{
				'label--lg': size === 'lg',
				'label--invalid': fieldInvalid
			}"
		>
			{{ label }}
			<span class="text text--danger" *ngIf="hasRequiredValidator">*</span></label
		>
	</div>
	<div *ngIf="showFieldErrors" class="col col--flex col--content-end">
		<ng-container>
			<span class="text--danger" *ngIf="field.errors.required">Field required</span>
			<span class="text--danger" *ngIf="field.errors.pattern">Field invalid</span>
		</ng-container>
	</div>
</div>
<textarea
	[class]="cssClass"
	[class.visual-debug]="debugMode && fieldChanged"
	#inputRef
	[ngClass]="{
		'text-input--invalid': fieldInvalid,
		'focused-on-page-enter': focusOnPageEnter,
		'auto-height': autoSize && !isEdit
	}"
	rows="5"
	[placeholder]="placeholder"
	[readOnly]="readonly"
	(keydown.shift.enter)="$event.preventDefault()"
	[formControl]="field"
	(blur)="onBlur.emit(field.value)"
	(input)="emitChange()"
></textarea>

<div *ngIf="message" class="message">
	{{ message }}
</div>

<app-input-error-messages
	*ngIf="!message"
	[field]="field"
	[showFieldErrors]="showFieldErrors && !!label"
></app-input-error-messages>
