<div>
	<div class="row" *ngIf="label">
		<div class="col">
			<label
				class="label"
				(click)="onLabelClick()"
				[ngClass]="{
					'label--lg': size === 'lg',
					'label--invalid': fieldInvalid
				}"
			>
				{{ label }}
				<span class="text text--danger" *ngIf="hasRequiredValidator && showRequiredStar">*</span>
				<a [href]="openInNewTabLink" target="_blank" *ngIf="openInNewTabLink">
					<app-icon-new-tab class="link" [iconCls]="'icon--small-link icon--gray-6'"></app-icon-new-tab>
				</a>
				<span class="profile" *ngIf="labelBadgeText" (click)="labelBadgeClicked.emit()">
					{{ labelBadgeText }}
				</span>
				<app-icon-info
					*ngIf="showInfoTooltip"
					iconCls="icon--url-color icon--small icon-color icon-info"
					[ngClass]="{
						'icon-info--error': field.errors?.pattern
					}"
					[matTooltip]="tooltip"
				></app-icon-info>
			</label>
		</div>
		<span *ngIf="dangerAdditionalInfo" class="text--danger label">{{ dangerAdditionalInfo }}</span>
		<ng-container *ngIf="url && href">
			<div class="col--flex col--content-end label">
				<a [href]="href" target="_blank">{{ url }}</a>
			</div>
		</ng-container>
		<ng-container *ngIf="badgeText">
			<div class="col--flex col--content-end label">
				<span (click)="badgeClicked.emit()" class="new">{{ badgeText }}</span>
			</div>
		</ng-container>
	</div>
	<div class="row with-search__field-wrapper-default" [ngClass]="{ 'with-search__field-wrapper': withSearch }">
		<input
			[class]="cssClass"
			#inputRef
			[id]="componentId"
			[class.visual-debug]="debugMode && fieldChanged"
			[readOnly]="readonly"
			[ngClass]="{
				'text-input--password': initialType === InputType.Password,
				'text-input--invalid': fieldInvalid || invalidData,
				'focused-on-page-enter': focusOnPageEnter,
				'with-search__search-text-field': withSearch,
				'special-character__input': inputValueSufix,
				'special-character__input--left': inputValueSufix && textAlignLeft
			}"
			[type]="type"
			[autocomplete]="autocomplete"
			[placeholder]="placeholder"
			[formControl]="field"
			[step]="step"
			(input)="emitChange()"
			(change)="parseNumbers()"
			(keyup.enter)="enter.emit()"
			(wheel)="wheelEvent ? '' : $event.preventDefault()"
			(blur)="blurChanges()"
			(focus)="focusChanges()"
		/>
		<app-icon-view
			(click)="onChangeType()"
			*ngIf="initialType === InputType.Password"
			class="see-password"
			[iconCls]="type === InputType.Password ? 'icon--gray-4' : 'icon--inverse'"
		></app-icon-view>
		<span class="special-character" *ngIf="inputValueSufix">{{ inputValueSufix }}</span>
		<div class="delete-symbol__wrapper" *ngIf="clearable && field.value">
			<span class="delete-symbol__cursor" (click)="clear()">×</span>
		</div>
		<div class="with-search__search-icon-wrapper" *ngIf="withSearch && !isFieldDisabled">
			<app-icon-search
				class="with-search__search-icon"
				iconCls="icon--light"
				(click)="search(field?.value)"
			></app-icon-search>
		</div>
	</div>
	<app-input-error-messages [field]="field" [showFieldErrors]="showFieldErrors && !!label"></app-input-error-messages>
</div>
