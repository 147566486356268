import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'camelcaseDisplay',
	pure: true,
})
export class CamelcaseDisplayPipe implements PipeTransform {
	transform(value: string): unknown {
		if (value) {
			return value
				.split(/(?=[A-Z])/)
				.map(p => {
					return p.charAt(0).toUpperCase() + p.slice(1);
				})
				.join(' ');
		}
	}
}
