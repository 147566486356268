import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tab/tab.component';
import { IconMergeCheckComponent } from './icons/icon-merge-check/icon-merge-check.component';

@NgModule({
	declarations: [TabsComponent, TabComponent, IconMergeCheckComponent],
	imports: [CommonModule],
	exports: [TabsComponent, TabComponent],
})
export class TabsModule {}
