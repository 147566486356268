import { Component, Input, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-new-tab',
	templateUrl: './icon-new-tab.component.html',
	styleUrls: ['./icon-new-tab.component.scss'],
})
export class IconNewTabComponent extends BaseIcon implements OnInit {
	@Input() url: string;

	constructor() {
		super();
	}

	ngOnInit() {}

	openLinkInNewTab() {
		if (this.url) {
			window.open(this.url, '_blank');
		}
	}
}
