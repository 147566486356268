import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
  selector: 'app-icon-dashboard',
  templateUrl: './icon-dashboard.component.html',
  styleUrls: ['./icon-dashboard.component.scss']
})
export class IconDashboardComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
