export enum SideInfoType {
	Company = 'Company',
	Facility = 'Facility',
	PrivateLabeler = 'PrivateLabeler',
	Contact = 'Contact',
	Role = 'Role',
	Brand = 'Brand',
	Note = 'Note',
	Application = 'Application',
	Visit = 'Visit',
	Trips = 'Trips',
	Tasks = 'Tasks',
	Group = 'Group',
	MasterIngredients = 'MasterIngredients',
	CustomerIngredient = 'CustomerIngredient',
	IngredientBatch = 'IngredientBatch',
	Product = 'Product',
	Invoice = 'Invoice',
	Notification = 'Notification',
	MagicReview = 'MagicReview',
	CompanySnapshots = 'CompanySnapshots',
	ProductReviewQueue = 'ProductReviewQueue',
}
