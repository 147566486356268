import { PageTitleActions, PageTitleActionTypes, PageTitleUpdateType } from '../actions/page-title.action';

export interface PageTitleState {
	title: string;
	entityName?: string;
	route: string;
	applicationId: string;
}

export const initialState: PageTitleState = {
	title: undefined,
	entityName: undefined,
	route: undefined,
	applicationId: undefined,
};

export function pageTitleReducer(state = initialState, action: PageTitleActions): PageTitleState {
	switch (action.type) {
		case PageTitleActionTypes.PageTitleUpdate:
			switch (action.payload.updateType) {
				case PageTitleUpdateType.ENTITTY:
					return {
						title: state.title,
						entityName: action.payload.entityName,
						route: state.route,
						applicationId: state.applicationId,
					};
				case PageTitleUpdateType.ALL:
				default:
					return {
						title: action.payload.pageTitle,
						entityName: action.payload.entityName,
						route: action.payload.route,
						applicationId: action.payload.applicationId,
					};
			}

		default:
			return state;
	}
}
