import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { EntityType } from '../enums/entity-type.enum';
import { NoteGroupType } from '../enums/note-group-type.enum';
import { NoteType } from '../enums/note-type.enum';
import { NoteGroup } from '../models/note-group.model';
import { Note, NoteComment } from '../models/note.model';
import { PickNote } from '../models/picknote.model';

@Injectable({
	providedIn: 'root',
})
export class NotesService {
	reloadNotesCount$ = new Subject<void>();

	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	private get noteController() {
		return `${this.appConfigurationService.api?.note}/note`;
	}

	load(noteId): Observable<Note> {
		return this.http.get<any>(`${this.noteController}/load`, { params: { noteId } });
	}

	loadByEntityType(entityId: number, entityTypeId: number, noteTypeId?: NoteType): Observable<Note[]> {
		const url = noteTypeId
			? `${this.noteController}/${entityId}/${entityTypeId}/load?noteTypeId=${noteTypeId}`
			: `${this.noteController}/${entityId}/${entityTypeId}/load`;

		return this.http.get<Note[]>(url);
	}

	loadByNoteType(entityId: number, entityTypeId: number, noteTypeId: NoteType): Observable<Note[]> {
		return this.http.get<Note[]>(`${this.noteController}/${entityId}/${entityTypeId}/${noteTypeId}/load`);
	}

	loadListByEntityType(entityId, entityTypeId): Observable<Note[]> {
		return this.http.get<Note[]>(`${this.noteController}/${entityId}/${entityTypeId}/list`);
	}

	loadMultiple(data: { entityId: number; entityTypeId: EntityType; noteTypeId?: NoteType }[]): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.noteController}/loadMultiple`, data);
	}

	loadByEntityHistory(entityHistoryId, entityTypeId) {
		return this.http.get<any>(`${this.noteController}/${entityHistoryId}/${entityTypeId}/history`);
	}

	save(note): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.noteController}/save`, note);
	}

	saveMultiple(notes: Note[]): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.noteController}/save/multiple`, notes);
	}

	deleteNote(noteId: any) {
		return this.http.post(`${this.appConfigurationService.api.note}/Note/delete?noteId=${noteId}`, null);
	}

	getNoteSummary(id) {
		return this.http.get(`${this.appConfigurationService.api.service}/Summary/note?id=${id}`);
	}

	getPickNotes(pickNoteTypeId) {
		return this.http.get(`${this.appConfigurationService.api.note}/Note/${pickNoteTypeId}/loadNotePicklistByType`);
	}

	savePickNote(pickNote: PickNote): Observable<PickNote> {
		return this.http.post<PickNote>(`${this.appConfigurationService.api.service}/note/saveNotePicklist`, pickNote);
	}

	saveComment(comment: NoteComment): Observable<NoteComment> {
		return this.http.post<NoteComment>(`${this.noteController}/comment`, comment);
	}

	loadNoteAttachments(entityIds: any) {
		return this.http.post(`${this.appConfigurationService.api.serviceProvider}/Document/Entity/Load`, entityIds);
	}

	getNoteCount(entityId: number, entityTypeId: EntityType, noteTypeId: NoteType): Observable<number> {
		return this.http.get<number>(
			`${this.appConfigurationService.api.note}/Note/${entityId}/${entityTypeId}/${noteTypeId}/count`
		);
	}

	loadMultipleByNoteGroupTypeId(noteGroupTypeId: NoteGroupType, noteGroup: NoteGroup[]): Observable<Note[]> {
		return this.http.post<Note[]>(
			`${this.appConfigurationService.api.service}/Note/${noteGroupTypeId}/loadMultipleByNoteGroupTypeId`,
			noteGroup
		);
	}

	loadMultipleByNoteTypeId(noteGroup: NoteGroup[]): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.appConfigurationService.api.service}/Note/loadMultiple`, noteGroup);
	}
}
