<div [class.rounded]="roundCheckbox" class="wrapper">
	<label
		[class]="inputCls"
		[class.justify-center]="centered"
		[class.disabled]="disabled || readonly"
		[ngClass]="{ rounded__label: roundCheckbox, checkbox: !roundCheckbox }"
		><span *ngIf="label" class="m-l-5">{{ label }}</span>
		<input
			[readOnly]="readonly"
			type="checkbox"
			class="checkbox__input"
			[formControl]="field"
			(click)="$event.stopImmediatePropagation()"
			(change)="emitChange()"
		/>
		<span
			*ngIf="!roundCheckbox"
			[class.visual-debug]="debugMode && fieldChanged"
			class="check-mark checkbox__check-mark"
		></span>

		<app-icon-check
			*ngIf="roundCheckbox && field.value && roundCheckboxStyle === 'green'"
			iconCls="icon--success icon--no-align"
		></app-icon-check>
		<app-icon-check
			*ngIf="roundCheckbox && field.value && roundCheckboxStyle === 'red'"
			iconCls="icon--danger icon--no-align"
		></app-icon-check>
		<app-icon-check *ngIf="roundCheckbox && !field.value" [iconCls]="getCheckMarkDefaultClasses()"></app-icon-check>
	</label>
</div>
