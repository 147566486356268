import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { InputBase } from 'src/app/shared/component-bases/input-base';

@Component({
	selector: 'app-textarea-input',
	templateUrl: './textarea-input.component.html',
	styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent extends InputBase implements OnInit, AfterViewInit {
	@Input() message: string;
	@Input() focusOnPageEnter: boolean = false;
	@Input() autoSize = false;
	@Input() isEdit = false;

	constructor() {
		super();
	}

	ngOnInit() {
		this.initInput();
		if (this.autoSize) {
			this.field.valueChanges.subscribe(() => {
				this.setHeight();
			});
		}
	}

	ngAfterViewInit() {
		this.setHeight();
	}

	get cssClass() {
		let sizeCls = '';
		switch (this.size) {
			case 'lg':
				sizeCls = ' text-input--lg';
				break;
		}

		return 'text-input ' + (this.inputCls || '') + sizeCls;
	}

	onLabelClick() {
		this.inputRef.nativeElement.focus();
	}

	setHeight() {
		if (this.autoSize) {
			this.inputRef.nativeElement.style.height = '33px';
			this.inputRef.nativeElement.style.height = `${this.inputRef.nativeElement.scrollHeight + 2}px`;
		}
	}
}
