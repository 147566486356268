import { Action } from '@ngrx/store';
import { ApplicationDetail, ApplicationBusinessEntity, ApplicationFacility } from '../../core/models/application.model';

export enum ApplicationActionTypes {
	ApplicationSummaryLoad = '[Application] application load',
	ApplicationSummaryLoaded = '[Application] application summary loaded',
	ApplicationSummaryLoading = '[Global] Application Summary Loading',
	ApplicationSummarySaveReferral = '[Referral Component] update application referral summary and save',
	ApplicationSummarySaveGeneral = '[Application General Component] update application general summary and save',
	ApplicationSummarySave = '[Application Summary Global] Save',
	ApplicationSummarySaveSuccess = '[Application Summary Global] Save Success',
	ApplicationPublishUpdate = '[Application] application publish',
	ApplicationFacilitiesLoad = '[Application Facilities] facilities load',
	ApplicationFacilitiesLoaded = '[Application Facilities] facilities loaded',
	ApplicationFacilitiesSave = '[Application Facilities Global] Save',
	ApplicationFacilitiesSaveSuccess = '[Application Facilities Global] Save Success',
	ApplicationFacilitySelect = '[Global] ApplicationFacilitySelect',
	ApplicationFacilitySaveResult = '[Global] Project Save result',
	ApplicationCleanUp = '[Global] Application Clean',
}

export class ApplicationSummaryLoad implements Action {
	readonly type = ApplicationActionTypes.ApplicationSummaryLoad;

	constructor(public payload: { applicationId: number }) {}
}

export class ApplicationSummaryLoaded implements Action {
	readonly type = ApplicationActionTypes.ApplicationSummaryLoaded;

	constructor(public payload: { appSummary: ApplicationDetail }) {}
}

export class ApplicationSummaryLoading implements Action {
	readonly type = ApplicationActionTypes.ApplicationSummaryLoading;
	constructor(public payload: { appSummaryLoading: boolean }) {}
}

export class ApplicationSummarySaveReferral implements Action {
	readonly type = ApplicationActionTypes.ApplicationSummarySaveReferral;

	constructor(public payload: { referralInfo: any; referralFees: any[] }) {}
}

export class ApplicationSummarySaveGeneral implements Action {
	readonly type = ApplicationActionTypes.ApplicationSummarySaveGeneral;

	constructor(public payload: { generalInfo: any }) {}
}

export class ApplicationSummarySave implements Action {
	readonly type = ApplicationActionTypes.ApplicationSummarySave;

	constructor() {}
}

export class ApplicationSummarySaveSuccess implements Action {
	readonly type = ApplicationActionTypes.ApplicationSummarySaveSuccess;

	constructor() {}
}

export class ApplicationPublishUpdate implements Action {
	readonly type = ApplicationActionTypes.ApplicationPublishUpdate;

	constructor(public payload: { applicationPublished: boolean }) {}
}

export class ApplicationFacilitiesLoad implements Action {
	readonly type = ApplicationActionTypes.ApplicationFacilitiesLoad;

	constructor(public payload: { appFacilitiesEntities: ApplicationBusinessEntity[] }) {}
}

export class ApplicationFacilitiesLoaded implements Action {
	readonly type = ApplicationActionTypes.ApplicationFacilitiesLoaded;

	constructor(public payload: { appFacilities: ApplicationFacility[] }) {}
}

export class ApplicationFacilitiesSave implements Action {
	readonly type = ApplicationActionTypes.ApplicationFacilitiesSave;

	constructor(public payload: { facility: ApplicationFacility; validateAddress?: boolean }) {}
}

export class ApplicationFacilitiesSaveSuccess implements Action {
	readonly type = ApplicationActionTypes.ApplicationFacilitiesSaveSuccess;

	constructor(public payload: { facility: ApplicationFacility }) {}
}

export class ApplicationFacilitySelect implements Action {
	readonly type = ApplicationActionTypes.ApplicationFacilitySelect;

	constructor(public payload: { facility: ApplicationFacility }) {}
}

export class ApplicationFacilitySaveResult implements Action {
	readonly type = ApplicationActionTypes.ApplicationFacilitySaveResult;

	constructor(public payload: { facility: ApplicationFacility }) {}
}

export class ApplicationCleanUp implements Action {
	readonly type = ApplicationActionTypes.ApplicationCleanUp;
}

export type ApplicationActions =
	| ApplicationSummaryLoad
	| ApplicationSummaryLoaded
	| ApplicationSummarySaveReferral
	| ApplicationSummarySaveGeneral
	| ApplicationSummarySave
	| ApplicationSummarySaveSuccess
	| ApplicationPublishUpdate
	| ApplicationFacilitiesLoad
	| ApplicationFacilitiesLoaded
	| ApplicationFacilitiesSave
	| ApplicationFacilitiesSaveSuccess
	| ApplicationFacilitySelect
	| ApplicationSummaryLoading
	| ApplicationFacilitySaveResult
	| ApplicationCleanUp;
