export enum GridColumnType {
	Text = 0,
	Boolean = 1,
	Check = 2,
	TextInput = 3,
	View = 4,
	NoteInput = 5,
	HTML = 6,
	RelativeDate = 7,
	Duration = 8,
	Button = 9,
	Progress = 10,
	UtcDate = 11,
	Status = 12,
	Attachment = 13,
	PreviewAndSendEmail = 14,
	Decimal = 15,
}
