import { Component, OnInit, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Note } from 'src/app/core/models/note.model';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NotesService } from 'src/app/core/services/notes.service';
import { NoteType } from 'src/app/core/enums/note-type.enum';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TextareaInputComponent } from '../textarea-input/textarea-input.component';

@Component({
	selector: 'app-note-form',
	templateUrl: './note-form.component.html',
	styleUrls: ['./note-form.component.scss'],
})
export class NoteFormComponent implements OnInit {
	@ViewChild('input', { static: true }) input: TextareaInputComponent;
	@Input() entityId: number = null;
	@Input() entityTypeId: number;
	@Input() noteTypeId: NoteType;
	@Input() hasHeader = true;
	@Input() showLabel = true;
	@Input() labelText = 'Notes';
	@Input() marginCls = 'm-t-20';
	@Input() readonly = false;
	@Input() showMessage = true;
	@Input() loadAll = false;
	@Input() inputSize = false;
	@Input() addMode = false;
	@Input() showPlaceHolder = true;
	@Input() inputCls: string;

	notes: Note[] = [];

	noteForm: FormGroup;
	changesOnForm = false;

	public isNoteLoading: boolean;

	constructor(public activatedRoute: ActivatedRoute, private fb: FormBuilder, private noteSvc: NotesService) {
		this.noteForm = this.fb.group({
			noteId: ['', []],
			entityId: ['', []],
			entityTypeId: ['', []],
			value: ['', []],
		});
	}

	ngOnInit() {
		if (this.entityId > 0) {
			this.reloadInfo();
		} else {
			this.onLoadNote([]);
		}

		this.noteForm.valueChanges.subscribe(x => {
			this.changesOnForm = true;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			((changes.entityId && !changes.entityId.isFirstChange()) ||
				(changes.entityTypeId && !changes.entityTypeId.isFirstChange())) &&
			!this.addMode
		) {
			this.reloadInfo();
		}
	}

	reloadInfo() {
		if (this.entityId > 0) {
			this.isNoteLoading = true;
			if (this.loadAll) {
				this.noteSvc
					.loadListByEntityType(this.entityId, this.entityTypeId)
					.subscribe((note: Note[]) => this.onLoadNote(note));
			} else {
				this.noteSvc
					.loadByEntityType(this.entityId, this.entityTypeId, this.noteTypeId)
					.subscribe((note: Note[]) => this.onLoadNote(note));
			}
		}
	}

	public getFormChanged() {
		return this.changesOnForm;
	}

	get form() {
		return this.noteForm.controls;
	}

	save(entityId = null, entityHistoryId = null) {
		let note: Note = this.noteForm.value;
		if (((this.notes && this.notes.length) || note.value) && this.hasChanges) {
			this.isNoteLoading = true;
			note = this.manageNote(note);
			if (entityHistoryId && entityHistoryId > 0) {
				note.entityHistoryId = entityHistoryId;
			}
			if (entityId && entityId > 0) {
				note.entityId = entityId;
			}
			this.noteSvc.save(note).subscribe((note: Note[]) => {
				this.reloadInfo();
				this.isNoteLoading = false;
			});
		} else {
			return;
		}
	}

	saveAsync(entityId = null, entityHistoryId = null): Observable<Note[]> {
		let note: Note = this.noteForm.value;
		if (((this.notes && this.notes.length) || note.value) && this.hasChanges) {
			this.isNoteLoading = true;
			note = this.manageNote(note);
			if (entityHistoryId && entityHistoryId > 0) {
				note.entityHistoryId = entityHistoryId;
			}
			if (entityId && entityId > 0) {
				note.entityId = entityId;
			}
			return this.noteSvc.save(note).pipe(
				tap(res => {
					this.reloadInfo();
				})
			);
		} else {
			return of([]);
		}
	}

	onLoadNote(note: Note[]) {
		this.notes = note;
		if (note && note.length) {
			if (this.noteTypeId) {
				const selectNote = note.find(n => n.noteTypeId === this.noteTypeId);
				selectNote ? this.noteForm.patchValue(selectNote) : this.initEmptyNote();
			} else {
				this.noteForm.patchValue(note[0]);
			}
		} else {
			this.initEmptyNote();
		}
		this.isNoteLoading = false;
	}

	discardChanges() {
		if (this.hasChanges) {
			this.noteForm.controls.value.patchValue(this.currentNote);
		}
	}

	reset() {
		this.noteForm.reset();
	}

	private initEmptyNote() {
		this.noteForm.patchValue({
			noteId: -1,
			entityId: this.entityId,
			entityTypeId: this.entityTypeId,
			value: '',
		});
	}

	private manageNote(note) {
		if (!this.notes.length) {
			note.entityId = this.entityId;
			note.noteId = -1;
			note.entityTypeId = this.entityTypeId;
		}
		if (!this.noteTypeId) {
			note.noteTypeId = NoteType.GeneralNotes;
		} else {
			note.noteTypeId = this.noteTypeId;
		}

		return note;
	}

	get hasChanges() {
		return this.currentNote != this.noteFormValue;
	}

	get currentNote() {
		if (this.noteTypeId) {
			const selectNote = this.notes?.find(n => n.noteTypeId === this.noteTypeId);
			return selectNote?.value ?? '';
		}
		return this.notes.length ? this.notes[0].value : '';
	}

	get noteFormValue() {
		return this.noteForm.value ? this.noteForm.value.value : '';
	}
}
