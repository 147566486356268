<div class="modal-content modal-confirm">
	<div class="modal-header modal-confirm__header">
		<button type="button" class="close" (click)="close()">&times;</button>
		<app-icon-warning class="modal-confirm__icon" iconCls="icon--danger"></app-icon-warning>
		<h4 class="modal-confirm__title">Are you sure?</h4>
	</div>
	<div class="modal-body modal-confirm__message">
		<p>Do you want to save your changes?</p>
	</div>
	<div class="modal-footer modal-confirm__footer">
		<button type="button" class="btn btn--gray-1 m-r-10" (click)="onClose(1)">Cancel</button>
		<button type="button" class="btn btn--secondary m-r-10" (click)="onClose(2)">Don't Save</button>
		<button type="button" class="btn btn--primary" (click)="onClose(3)">Save</button>
	</div>
</div>
