import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	BaseModel,
	JobModel,
	BaseModelCategory,
	CountryModel,
	VisitStatusBaseModel,
	BaseIndexModel,
	ColorBaseModel,
	BaseModelEvaluationGroup,
} from '../models/base.model';
import { ContactMethod } from '../models/contact.model';
import { EntityLinkTypeModel } from '../models/entity-link.model';
import { TaskEntityType } from '../models/task-entity-type.model';
import { CategoryHeader } from '../enums/category-header.enum';
import { VisitType } from '../models/visit.model';
import { DocumentInfo } from '../models/document.model';
import { EntityType } from '../enums/entity-type.enum';
import { LifecycleCategories } from '../enums/lifecycle-category.enum';
import { Attribute } from '../models/attribute.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { TaskStatusGroup } from '../enums/task-status-group.enum';
import { TaskStatusGroupModel } from '../models/tasks-status-group.model';
import { BusinessEntityBase } from '../models/business-entity.model';
import { LifeCycleStatus } from '../models/lifecycle-status.model';
import { DependencyLevelType } from '../models/products/dependency-level-type.model';
import { IngredientStatusModel } from '../models/ingredient-status.model';
import { NoteGroupType } from '../enums/note-group-type.enum';
import { CertificateAttributeIndexModel, EvaluationAttributeIndexModel } from '../models/ingredients/evaluation.model';

@Injectable({
	providedIn: 'root',
})
export class IndexService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	get(method): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/${method}`);
	}

	getCountries(): Observable<CountryModel[]> {
		return this.http.get<CountryModel[]>(`${this.appConfigurationService.api.settings}/index/countries`);
	}

	getContinents(): Observable<BaseIndexModel[]> {
		return this.http.get<BaseIndexModel[]>(`${this.appConfigurationService.api.settings}/index/continents`);
	}

	getWorkProcess(useDefaultValue = true) {
		return this.http.get<any>(`${this.appConfigurationService.api.settings}/index/work_process`, {
			params: { useDefaultValue: useDefaultValue.toString() },
		});
	}

	getContractStatuses(useDefaultValue = false) {
		return this.http.get<any>(`${this.appConfigurationService.api.settings}/index/contract_statuses`, {
			params: { useDefaultValue: useDefaultValue.toString() },
		});
	}

	getStates(countryId): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/states`, {
			params: { countryId },
		});
	}

	getBusinessCategories(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/business_categories`);
	}

	getContactTypes(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/contact_types`);
	}

	getFinancialInvoiceTypes(useDefaultValue: boolean): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/financial_invoice_types?useDefaultValue=${useDefaultValue}`
		);
	}

	getContactRoleTypes(isRequired: boolean = null, useDefaultValue: boolean = false): Observable<BaseModel[]> {
		let params = {};
		if (isRequired != null) {
			params = { isRequired: isRequired.toString(), useDefaultValue: useDefaultValue.toString() };
		} else {
			params = { useDefaultValue: useDefaultValue.toString() };
		}

		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/contact_role_types`, {
			params: params,
		});
	}

	getAgreementTypes(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/agreement_types`, {
			params: { useDefaultValue: useDefaultValue.toString() },
		});
	}

	getAgreementTerms(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/agreement_terms`);
	}

	getContactRepTypes(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/contact_rep_types`, {
			params: { useDefaultValue: useDefaultValue.toString() },
		});
	}

	getJobs(main: boolean = null): Observable<JobModel[]> {
		let params = {};
		if (main != null) {
			params = { main: main.toString() };
		}
		return this.http.get<JobModel[]>(`${this.appConfigurationService.api.settings}/index/jobs`, { params });
	}

	saveJob(jobName: any): Observable<BaseModel> {
		return this.http.post<BaseModel>(`${this.appConfigurationService.api.settings}/index/save/job`, {
			name: jobName,
		});
	}

	getJob(id: number): Observable<JobModel> {
		return this.http.get<JobModel>(`${this.appConfigurationService.api.settings}/index/job`, {
			params: { id: id.toString() },
		});
	}

	getTitleTypes(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/title_types`);
	}

	getBusinessEntitySummary(id): Observable<any> {
		return this.http.get<any>(`${this.appConfigurationService.api.businessEntity}/BussinessEntity/${id}/Summary`);
	}

	getApplicationSummary(id: number): Observable<any> {
		return this.http.get<any>(`${this.appConfigurationService.api.service}/Summary/application?id=${id}`);
	}

	getContactMethods(): Observable<ContactMethod[]> {
		return this.http.get<ContactMethod[]>(`${this.appConfigurationService.api.settings}/index/contact_methods`);
	}

	getNoteTypes(useDefaultValue: boolean, isMain: boolean, noteGroupType?: NoteGroupType): Observable<BaseModel[]> {
		const params = {
			useDefaultValue: useDefaultValue.toString(),
		};

		if (isMain) {
			params['isMain'] = isMain;
		}

		if (noteGroupType) {
			params['noteGroupType'] = noteGroupType;
		}

		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/note_types`, {
			params,
		});
	}

	getPickNoteTypes(useDefaultValue): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/pick_note_types`, {
			params: {
				useDefaultValue: useDefaultValue.toString(),
			},
		});
	}

	getLocations(useDefaultValue) {
		return this.http.get(`${this.appConfigurationService.api.settings}/index/locations`, {
			params: {
				useDefaultValue: useDefaultValue.toString(),
			},
		});
	}

	getCommissionTypes(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/commission_types`, {
			params: {
				useDefaultValue: useDefaultValue.toString(),
			},
		});
	}

	getCurrencies(useDefaultValue) {
		return this.http.get(`${this.appConfigurationService.api.settings}/index/currencies`, {
			params: {
				useDefaultValue: useDefaultValue.toString(),
			},
		});
	}

	getApplicationSources(useDefaultValue): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/application_sources`, {
			params: {
				useDefaultValue: useDefaultValue.toString(),
			},
		});
	}

	getRabinicals() {
		return this.http.get(`${this.appConfigurationService.api.contact}/contact/rabinicals`);
	}

	getVisitFrequencies(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/visit_frequencies`);
	}

	getVisitTypes(useDefaultValue = false): Observable<VisitType[]> {
		return this.http.get<VisitType[]>(`${this.appConfigurationService.api.settings}/index/visit_types`, {
			params: { useDefaultValue: useDefaultValue.toString() },
		});
	}

	getBusinessEntityTypes(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/business_entity_types`);
	}

	getBusinessEntityApplicationTypes(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/application_types?useDefaultValue=${useDefaultValue}`
		);
	}

	getBusinessEntityLinkTypes(entityLinkId: number, useDefaultValue = false): Observable<EntityLinkTypeModel[]> {
		return this.http.get<EntityLinkTypeModel[]>(
			`${this.appConfigurationService.api.settings}/index/business_entity_link_types`,
			{
				params: {
					entityLinkId: entityLinkId.toString(),
					useDefaultValue: useDefaultValue.toString(),
				},
			}
		);
	}

	getApplicationStatus(id) {
		return this.http.get(`${this.appConfigurationService.api.settings}/index/application_status?id=${id}`);
	}

	getApplicationStatuses(useDefaultValue = false): Observable<BaseModelCategory[]> {
		return this.http.get<BaseModelCategory[]>(
			`${this.appConfigurationService.api.settings}/index/application_statuses?useDefaultValue=${useDefaultValue}`
		);
	}

	getVisitStatus(useDefaultValue = false): Observable<VisitStatusBaseModel[]> {
		return this.http.get<VisitStatusBaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/visit_status`,
			{
				params: {
					useDefaultValue: useDefaultValue.toString(),
				},
			}
		);
	}

	getNatureOfFacility(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/nature_of_facility?useDefaultValue=${useDefaultValue}`
		);
	}

	getProcessReasons(reasonTypeId: number): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/process_reasons?reasonTypeId=${reasonTypeId}`
		);
	}

	getInvoiceTypes(entityTypeId: number, applicationTypeId: number): Observable<BaseModel[]> {
		let queryParams = `entityTypeId=${entityTypeId}`;
		if (applicationTypeId) {
			queryParams += `&applicationTypeId=${applicationTypeId}`;
		}
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/invoice_types?${queryParams}`
		);
	}

	getInvoiceStatuses(useDefaultValue = false) {
		return this.http.get<any>(`${this.appConfigurationService.api.settings}/index/invoice_statuses`, {
			params: { useDefaultValue: useDefaultValue.toString() },
		});
	}

	getTaskEntityTypes(): Observable<TaskEntityType[]> {
		return this.http.get<TaskEntityType[]>(`${this.appConfigurationService.api.settings}/index/task_entity`);
	}

	getTaskEntityRelatedTypes(useDefaultValue = false): Observable<TaskEntityType[]> {
		return this.http.get<TaskEntityType[]>(
			`${this.appConfigurationService.api.settings}/index/task_entity_related`,
			{
				params: { useDefaultValue },
			}
		);
	}

	getTaskPriorities(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_priority`);
	}

	getTaskStatusesByGroup(
		taskStatusGroup = TaskStatusGroup.All,
		useDefaultValue = false
	): Observable<TaskStatusGroupModel[]> {
		return this.http.get<TaskStatusGroupModel[]>(
			`${this.appConfigurationService.api.settings}/index/task_status_by_group`,
			{
				params: { taskStatusGroup, useDefaultValue },
			}
		);
	}

	getTaskModels(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_model`);
	}

	getTaskStages(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_stage`);
	}

	getTaskTypes(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_type`);
	}

	getTaskSources(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_source`);
	}

	getTaskPrivacyOptions(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_privacy`);
	}

	getTaskIssueTypes(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_issue`, {
			params: { useDefaultValue },
		});
	}

	getTaskResolutions(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/task_resolution`);
	}

	getPlatforms(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/platform`);
	}

	getPermissions(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.security}/permission/loadall`);
	}

	getPermissionRole(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.security}/role/loadall`);
	}

	getIngredientEvaluationStatuses() {
		return this.http.get<any>(`${this.appConfigurationService.api.settings}/index/evaluation_statuses`);
	}

	getTwoFactorAuthentication(platformId: number): Observable<boolean> {
		return this.http.get<boolean>(
			`${this.appConfigurationService.api.settings}/index/platform/twoFactorAuthentication?platformId=${platformId}`
		);
	}

	getIngredientCertificateStatuses(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/certificate_statuses`);
	}

	getIngredientEvaluationAttributes(statusId: number): Observable<EvaluationAttributeIndexModel[]> {
		return this.http.get<EvaluationAttributeIndexModel[]>(
			`${this.appConfigurationService.api.settings}/index/evaluation_status_attributes?statusId=${statusId}`
		);
	}

	getIngredientCertificateAttributes(statusId: number): Observable<CertificateAttributeIndexModel[]> {
		return this.http.get<CertificateAttributeIndexModel[]>(
			`${this.appConfigurationService.api.settings}/index/certificate_status_attributes?statusId=${statusId}`
		);
	}

	getEvaluationGroups(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/evaluation_groups`);
	}

	getEvaluationGroupVariations(): Observable<BaseIndexModel[]> {
		return this.http.get<BaseIndexModel[]>(
			`${this.appConfigurationService.api.settings}/index/evaluation_group_variations`
		);
	}

	getIngredientCategories(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/ingredient_category_types`
		);
	}

	getVisitsByInspectionType(inspectionType: number) {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/${inspectionType}/visit_types`
		);
	}

	getEvaluationsApprovalStatuses(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/evaluation_approval_statuses`
		);
	}

	getEvaluationsLifecycleStatuses(): Observable<LifeCycleStatus[]> {
		return this.http.get<LifeCycleStatus[]>(
			`${this.appConfigurationService.api.settings}/index/lifecycle_statuses_by_category?categoryId=${LifecycleCategories.KosherEvaluation}`
		);
	}

	getCategoryOfType(type: CategoryHeader): Observable<BaseModel[]> {
		return new Observable<BaseModel[]>(observer => {
			this.http
				.get<BaseModelCategory[]>(`${this.appConfigurationService.api.settings}/index/categories`)
				.subscribe(res => {
					const result = res
						.filter(r => +r.categoryHeaderId === +type)
						.map(t => {
							const model = new BaseModel();
							model.id = t.id;
							model.name = `${t.name}, #${t.id}`;
							return model;
						});
					observer.next(result);
				});
		});
	}

	getCategories(): Observable<BaseModelCategory[]> {
		return this.http.get<BaseModelCategory[]>(`${this.appConfigurationService.api.settings}/index/categories`);
	}

	getApplicationPDF(entityId: number, entityTypeId: number): Observable<DocumentInfo[]> {
		return this.http.get<DocumentInfo[]>(
			`${this.appConfigurationService.api.serviceProvider}/document/entity/${entityId}?entityTypeId=${entityTypeId}`
		);
	}

	getIngredientStatuses(isDefault: boolean): Observable<IngredientStatusModel[]> {
		return this.http.get<IngredientStatusModel[]>(
			`${this.appConfigurationService.api.settings}/index/ingredient_statuses?useDefaultValue=${isDefault}`
		);
	}

	getMarketedAs(): Observable<BaseModelCategory[]> {
		return this.http.get<BaseModelCategory[]>(`${this.appConfigurationService.api.settings}/index/marketed_as`);
	}

	packageTypes(): Observable<BaseModelCategory[]> {
		return this.http.get<BaseModelCategory[]>(`${this.appConfigurationService.api.settings}/index/package_type`);
	}

	getEntityAttributes(entityType: EntityType): Observable<Attribute[]> {
		return this.http.get<Attribute[]>(
			`${this.appConfigurationService.api.service}/index/Attributes?entityType=${entityType}`
		);
	}

	getProductSources(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/product_sources`);
	}

	getRecipientType(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/recipient_type`);
	}

	getEvaluationPassoverStatuses(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/evaluation_passover_statuses`
		);
	}

	getHandlingTypes(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/handling_types`);
	}

	getProcessingStatuses(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/processing_statuses`, {
			params: { useDefaultValue: useDefaultValue.toString() },
		});
	}

	getSubsets(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/subset_groups`);
	}

	getSearchIntervals(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/index/interval_types`);
	}

	getEntitySummary(entityId: number, entityTypeId: number): Observable<BusinessEntityBase> {
		return this.http.get<BusinessEntityBase>(
			`${this.appConfigurationService.api.service}/Summary/${entityId}/${entityTypeId}/title`
		);
	}

	getProductCategoryTypes(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/Index/product_category_types`, {
			params: { useDefaultValue },
		});
	}

	getSecurityLevelTypes(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.settings}/Index/security_level_types`);
	}

	getBatchIngredientReviewStatus(useDefaultValue = false): Observable<ColorBaseModel[]> {
		return this.http.get<ColorBaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/batch_ingredient_review_status?useDefaultValue=${useDefaultValue}`
		);
	}

	getFacilityIngredientUtilityStatus(useDefaultValue = false): Observable<ColorBaseModel[]> {
		return this.http.get<ColorBaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/facility_ingredient_utility_statuses?useDefaultValue=${useDefaultValue}`
		);
	}

	getStatusesByType(type: number, useDefaultValue = false): Observable<ColorBaseModel[]> {
		return this.http.get<ColorBaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/statuses_by_type?type=${type}&?useDefaultValue=${useDefaultValue}`
		);
	}

	getEvaluationGroupAttributes(groupId: number): Observable<BaseModelEvaluationGroup[]> {
		return this.http.get<BaseModelEvaluationGroup[]>(
			`${this.appConfigurationService.api.settings}/index/evaluation_group_attributes?groupId=${groupId}`
		);
	}

	getDependencyLevels(): Observable<DependencyLevelType[]> {
		return this.http.get<DependencyLevelType[]>(
			`${this.appConfigurationService.api.settings}/index/dependency_level_types`
		);
	}

	getProductTypes(useDefaultValue: boolean): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/product_types?useDefaultValue=${useDefaultValue}`
		);
	}

	getIdentitySourceType(): Observable<BaseIndexModel[]> {
		return this.http.get<BaseIndexModel[]>(
			`${this.appConfigurationService.api.settings}/index/identity_code_source_type`
		);
	}

	getCodeChangeUtilityUpdateFields(): Observable<BaseIndexModel[]> {
		return this.http.get<BaseIndexModel[]>(
			`${this.appConfigurationService.api.settings}/index/code_change_utility_update_field`
		);
	}

	getCodeChangeUtilityDataStatuses(): Observable<BaseIndexModel[]> {
		return this.http.get<BaseIndexModel[]>(
			`${this.appConfigurationService.api.settings}/index/code_change_utility_data_statuses`
		);
	}

	getTaskStatusesWithGroups(useDefaultValue = false): Observable<{ [id: number]: TaskStatusGroupModel[] }> {
		return this.http.get<{ [id: number]: TaskStatusGroupModel[] }>(
			`${this.appConfigurationService.api.settings}/index/task_status_with_groups?useDefaultValue=${useDefaultValue}`
		);
	}

	getCategoriesByCategoryHeaderId(headerId: number): Observable<BaseIndexModel[]> {
		return this.http.get<BaseIndexModel[]>(
			`${this.appConfigurationService.api.settings}/index/categories_by_category_header_id?categoryHeaderId=${headerId}`
		);
	}
}
