import { PermissionModule } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const settingsModuleTabs: Tab[] = [
	{
		name: 'Users',
		path: `/settings/users`,
		permissionId: PermissionModule.Users,
		isModule: true,
	},
	{
		name: 'Roles',
		path: `/settings/roles`,
		permissionId: PermissionModule.Roles,
		isModule: true,
	},
	{
		name: 'Permissions',
		path: `/settings/permissions`,
		permissionId: PermissionModule.Permissions,
		isModule: true,
	}
];
