import { PageTitleActions, PageTitleActionTypes } from '../actions/page-title.action';
import { SideInfoActions, SideInfoActionTypes } from '../actions/side-info.action';

export interface SideInfoState {
	visible: boolean;
	type?: any;
	extraParams?: any;
	asyncEntity: any;
	roleDetails: any;
	roleId: any;
	noteDetails: any;
	noteId: any;
	groupDetails: any;
	groupId: any;
	contactId: any;
}

export const initialState: SideInfoState = {
	visible: false,
	asyncEntity: {},
	roleDetails: undefined,
	roleId: undefined,
	noteDetails: undefined,
	noteId: undefined,
	groupDetails: undefined,
	groupId: undefined,
	contactId: undefined,
};

export function sideinfoReducer(state = initialState, action: SideInfoActions): SideInfoState {
	switch (action.type) {
		case SideInfoActionTypes.SideInfoToggle:
			return {
				visible: action.payload.visible,
				type: action.payload.type,
				extraParams: action.payload.extraParams,
				asyncEntity: action.payload.visible === state.visible ? state.asyncEntity : {},
				roleDetails: state.roleDetails,
				roleId: state.roleId,
				noteDetails: state.noteDetails,
				noteId: state.noteId,
				groupDetails: state.groupDetails,
				groupId: state.groupId,
				contactId: state.contactId,
			};
		case SideInfoActionTypes.SideInfoLoadedAsyncEntity:
			return {
				...state,
				asyncEntity: {
					[action.payload.type]: action.payload.asyncEntity,
				},
			};
		case SideInfoActionTypes.EditRoleUpdate:
			return {
				...state,
				roleDetails: action.payload.roleDetails,
			};
		case SideInfoActionTypes.DeleteRoleUpdate:
			return {
				...state,
				roleId: action.payload.roleId,
			};
		case SideInfoActionTypes.EditNoteUpdate:
			return {
				...state,
				noteDetails: action.payload.noteDetails,
			};
		case SideInfoActionTypes.DeleteNoteUpdate:
			return {
				...state,
				noteId: action.payload.noteId,
			};
		case SideInfoActionTypes.EditGroupUpdate:
			return {
				...state,
				groupDetails: action.payload.groupDetails,
			};
		case SideInfoActionTypes.DeleteGroupUpdate:
			return {
				...state,
				groupId: action.payload.groupId,
			};
		case SideInfoActionTypes.UnlinkContactUpdate:
			return {
				...state,
				contactId: action.payload.contactId,
			};
		default:
			return state;
	}
}
