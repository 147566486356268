import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUpload, FileUploadResponse } from 'src/app/core/models/file-upload.model';
import { CodeChangeUtility } from 'src/app/core/models/code-change-utility.model';
import { IngredientFacility } from 'src/app/core/models/facility.model';
import { UpdateIngredientFacilities } from 'src/app/core/models/ingredients-facilities-model';
import { CustomerIngredientExtended } from 'src/app/core/models/ingredients/customer-ingredient.model';
import { PurchaseHistory } from 'src/app/core/models/purchase-history.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import {
	CustomerIngredientStaticBarSummary,
	CustomerIngredientSummary,
} from '../../../../core/interfaces/customer-ingredient-summary.interface';
import { BatchSave } from '../../../../core/models/ingredients/batch-ingredient.model';

@Injectable({
	providedIn: 'root',
})
export class CustomerIngredientsService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	load(id: number): Observable<CustomerIngredientExtended> {
		return this.http.get<CustomerIngredientExtended>(
			`${this.appConfigurationService.api.product}/customeringredient/load?id=${id}`
		);
	}

	loadMultiple(customerIngredientIds: number[]): Observable<CustomerIngredientExtended[]> {
		return this.http.post<CustomerIngredientExtended[]>(
			`${this.appConfigurationService.api.service}/CustomerIngredient/loadMultiple`,
			customerIngredientIds
		);
	}

	loadWithAttributes(id: number): Observable<CustomerIngredientExtended> {
		return this.http.post<CustomerIngredientExtended>(
			`${this.appConfigurationService.api.service}/customeringredient/load?customerIngredientId=${id}`,
			null
		);
	}

	getCustomerIngredientSummary(customerIngredient: number) {
		return this.http.get<CustomerIngredientSummary>(
			`${this.appConfigurationService.api.service}/Summary/customerIngredient?id=${customerIngredient}`
		);
	}

	getCustomerIngredientStaticBarSummary(
		customerIngredientId: number
	): Observable<CustomerIngredientStaticBarSummary> {
		return this.http.get<CustomerIngredientStaticBarSummary>(
			`${this.appConfigurationService.api.product}/CustomerIngredient/${customerIngredientId}/Summary`
		);
	}

	save(customerIngredient: CustomerIngredientExtended) {
		return this.http.post<CustomerIngredientExtended>(
			`${this.appConfigurationService.api.product}/customeringredient/save`,
			customerIngredient
		);
	}

	inactivateReactivate(customerIngredient: CustomerIngredientExtended) {
		return this.http.post<CustomerIngredientExtended>(
			`${this.appConfigurationService.api.service}/customeringredient/Inactivate_Reactivate`,
			customerIngredient
		);
	}

	editMultipleCustomerIngredients(customerIngredientsIds: number[]): Observable<BatchSave[]> {
		return this.http.post<BatchSave[]>(
			`${this.appConfigurationService.api.service}/customeringredient/EditMultiple`,
			customerIngredientsIds
		);
	}

	updateIngredientFacilities(
		UpdateIngredientFacilities: UpdateIngredientFacilities
	): Observable<IngredientFacility[]> {
		return this.http.post<IngredientFacility[]>(
			`${this.appConfigurationService.api.product}/CustomerIngredient/Facility/SaveMultiple`,
			UpdateIngredientFacilities
		);
	}

	savePurchaseHistory(purchaseHistory: PurchaseHistory): Observable<PurchaseHistory> {
		return this.http.post<PurchaseHistory>(
			`${this.appConfigurationService.api.product}/CustomerIngredient/purchaseHistory/save`,
			purchaseHistory
		);
	}

	loadPurchaseHistoryItem(purchaseHistoryId: number): Observable<PurchaseHistory> {
		return this.http.get<PurchaseHistory>(
			`${this.appConfigurationService.api.product}/CustomerIngredient/purchaseHistory/load?purchaseHistoryId=${purchaseHistoryId}`,
			{}
		);
	}

	togglePurchaseHistoryItemStatus(purchaseHistoryId: number, active: boolean): Observable<PurchaseHistory> {
		return this.http.post<PurchaseHistory>(
			`${this.appConfigurationService.api.product}/CustomerIngredient/purchaseHistory/${purchaseHistoryId}/toggleActive?active=${active}`,
			{}
		);
	}

	loadCodeChangeUtility(codeChangeUtilityId: number): Observable<CodeChangeUtility> {
		return this.http.get<CodeChangeUtility>(
			`${this.appConfigurationService.api.product}/CodeChangeUtility/load?codeChangeUtilityId=${codeChangeUtilityId}`
		);
	}

	saveCodeChangeUtility(codeChangeUtility: CodeChangeUtility): Observable<CodeChangeUtility> {
		return this.http.post<CodeChangeUtility>(
			`${this.appConfigurationService.api.product}/CodeChangeUtility/save`,
			codeChangeUtility
		);
	}

	validateCodeChangeUtility(codeChangeUtility: CodeChangeUtility): Observable<CodeChangeUtility> {
		return this.http.post<CodeChangeUtility>(
			`${this.appConfigurationService.api.product}/CodeChangeUtility/validate`,
			codeChangeUtility
		);
	}

	processCodeChangeUtility(codeChangeUtility: CodeChangeUtility): Observable<CodeChangeUtility> {
		return this.http.post<CodeChangeUtility>(
			`${this.appConfigurationService.api.service}/CodeChangeUtility/process`,
			codeChangeUtility
		);
	}

	uploadCodeChangeUtility(codeChangeUtilityFile: FileUpload): Observable<FileUploadResponse> {
		return this.http.post<FileUploadResponse>(
			`${this.appConfigurationService.api.service}/File/codeChangeUtilityDatas/upload`,
			codeChangeUtilityFile
		);
	}
}
