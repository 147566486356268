import { MasterIngredientTabs } from '../../enums/permission-module.enum';

export const masterIngredientInfoTabs = [
	{
		name: 'Info',
		path: `/master-ingredient/:id`,
		permissionId: MasterIngredientTabs.Info,
	},
	{
		name: 'Companies',
		path: `/master-ingredient/:id/companies`,
		permissionId: MasterIngredientTabs.Companies,
	},
	{
		name: 'Projects',
		path: `/master-ingredient/:id/projects`,
		permissionId: MasterIngredientTabs.Facilities,
	},
	{
		name: 'Tasks',
		path: `/master-ingredient/:id/tasks`,
		permissionId: MasterIngredientTabs.Tasks,
	},
	{
		name: 'Kosher Letters',
		path: `/master-ingredient/:id/kosher-letters`,
		permissionId: MasterIngredientTabs.KosherLetters,
	},
	{
		name: 'Categories',
		path: `/master-ingredient/:id/categories`,
		permissionId: MasterIngredientTabs.Companies,
	},
	{
		name: 'History',
		path: `/master-ingredient/:id/history`,
		permissionId: MasterIngredientTabs.History,
	},
	{
		name: 'Notes',
		path: `/master-ingredient/:id/notes`,
		permissionId: MasterIngredientTabs.Notes,
	},
	{
		name: 'Changes History',
		path: `/master-ingredient/:id/changes-history`,
		permissionId: MasterIngredientTabs.ChangesHistory,
	},
	{
		name: 'Customer Ingredients',
		path: `/master-ingredient/:id/customer-ingredients`,
		permissionId: MasterIngredientTabs.CustomerIngredients,
	},
	{
		name: 'Dependent Products',
		path: `/master-ingredient/:id/dependent-products`,
		permissionId: MasterIngredientTabs.DependentProducts,
	},
	{
		name: 'Audit Trail',
		path: `/master-ingredient/:id/audit-trail`,
	},
];
