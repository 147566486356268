import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { LoginResponse } from 'src/app/core/models/auth/login.response';

export interface AuthState {
	loggedIn: boolean;
	loginData: LoginResponse;
}

export const initialAuthState: AuthState = {
	loggedIn: false,
	loginData: undefined,
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
	switch (action.type) {
		case AuthActionTypes.LoginAction:
			return {
				loggedIn: true,
				loginData: action.payload.loginResponse,
			};
		case AuthActionTypes.LogoutAction:
			return {
				loggedIn: false,
				loginData: undefined,
			};
		default:
			return state;
	}
}
