import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, take } from 'rxjs/operators';
import {
	ApplicationActionTypes,
	ApplicationFacilitiesLoad,
	ApplicationFacilitiesLoaded,
	ApplicationFacilitiesSave,
	ApplicationFacilitiesSaveSuccess,
	ApplicationFacilitySaveResult,
	ApplicationPublishUpdate,
	ApplicationSummaryLoad,
	ApplicationSummaryLoaded,
	ApplicationSummaryLoading,
	ApplicationSummarySave,
	ApplicationSummarySaveGeneral,
	ApplicationSummarySaveReferral,
	ApplicationSummarySaveSuccess,
} from '../actions/application.actions';
import { ApplicationStatus } from '../../core/enums/application-status.enum';
import { ApplicationsService } from '../../modules/applications/shared/services/applications.service';
import { AppState } from '../reducers';
import { selectApplicationSummary } from '../selectors/application.selectors';

@Injectable()
export class ApplicationEffects {
	@Effect({ dispatch: false })
	load$ = this.actions$.pipe(
		ofType<ApplicationSummaryLoad>(ApplicationActionTypes.ApplicationSummaryLoad),
		map(action => {
			this.store.dispatch(new ApplicationSummaryLoading({ appSummaryLoading: true }));
			this.applicationSvc.applicationInfo(action.payload.applicationId).subscribe(response => {
				if (response.statusId === ApplicationStatus.AppComplete) {
					this.store.dispatch(new ApplicationPublishUpdate({ applicationPublished: true }));
				}
				this.store.dispatch(
					new ApplicationSummaryLoaded({
						appSummary: response,
					})
				);
				this.store.dispatch(new ApplicationSummaryLoading({ appSummaryLoading: false }));
			});
		})
	);

	@Effect()
	saveApplicationReferralSummary$ = this.actions$.pipe(
		ofType<ApplicationSummarySaveReferral>(ApplicationActionTypes.ApplicationSummarySaveReferral),
		map(action => new ApplicationSummarySave())
	);

	@Effect()
	saveApplicationGeneralSummary$ = this.actions$.pipe(
		ofType<ApplicationSummarySaveGeneral>(ApplicationActionTypes.ApplicationSummarySaveGeneral),
		map(action => new ApplicationSummarySave())
	);
	@Effect({ dispatch: false })
	saveApplicationSummary$ = this.actions$.pipe(
		ofType<ApplicationSummarySave>(ApplicationActionTypes.ApplicationSummarySave),
		mergeMap(() => this.store.select(selectApplicationSummary).pipe(take(1))),
		map(applicationSummary => {
			this.applicationSvc.applicationInfoSave(applicationSummary).subscribe(appSummary => {
				this.store.dispatch(new ApplicationSummaryLoaded({ appSummary }));
				this.store.dispatch(new ApplicationSummarySaveSuccess());
			});
		})
	);

	@Effect({ dispatch: false })
	loadFacilities$ = this.actions$.pipe(
		ofType<ApplicationFacilitiesLoad>(ApplicationActionTypes.ApplicationFacilitiesLoad),
		map(action => {
			this.applicationSvc.getApplicationFacilities(action.payload.appFacilitiesEntities).subscribe(response => {
				this.store.dispatch(
					new ApplicationFacilitiesLoaded({
						appFacilities: Object.values(response),
					})
				);
			});
		})
	);

	@Effect({ dispatch: false })
	saveApplicationFacilities$ = this.actions$.pipe(
		ofType<ApplicationFacilitiesSave>(ApplicationActionTypes.ApplicationFacilitiesSave),
		map(action => {
			this.store.dispatch(new ApplicationFacilitySaveResult({ facility: null }));
			this.applicationSvc
				.saveApplicationFacilities([action.payload.facility], action.payload.validateAddress)
				.subscribe(response => {
					this.store.dispatch(new ApplicationFacilitySaveResult({ facility: response[0] }));
					this.store.dispatch(new ApplicationFacilitiesSaveSuccess({ facility: response[0] }));
				});
		})
	);

	constructor(
		private actions$: Actions,
		private applicationSvc: ApplicationsService,
		private store: Store<AppState>
	) {}
}
