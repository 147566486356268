import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { InputType } from 'src/app/core/enums/input-type.enum';
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_ENTER = 13;
const KEY_TAB = 9;

@Component({
	selector: 'app-text-input-cell-renderer',
	templateUrl: './text-input-cell-renderer.component.html',
	styleUrls: ['./text-input-cell-renderer.component.scss'],
})
export class TextInputCellRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;
	InputType = InputType;

	agInit(params: any): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}

	get isDisabled() {
		if (typeof this.params.node.data.disabled === 'object') {
			return (this.params.node.data.disabled || {})[this.params.colDef.colId];
		}

		return this.params.node.data.disabled;
	}

	onKeyDown(event: any): void {
		if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}

		if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event)) {
			if (event.preventDefault) event.preventDefault();
		}
	}

	private getCharCodeFromEvent(event: any): any {
		event = event || window.event;
		return typeof event.which == 'undefined' ? event.keyCode : event.which;
	}

	private isCharNumeric(charStr: string): boolean {
		return !!/\d/.test(charStr);
	}

	private isKeyPressedNumeric(event: any): boolean {
		const charCode = this.getCharCodeFromEvent(event);
		const charStr = event.key ? event.key : String.fromCharCode(charCode);
		return this.isCharNumeric(charStr);
	}

	private deleteOrBackspace(event: any) {
		return [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private isLeftOrRight(event: any) {
		return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private finishedEditingPressed(event: any) {
		const charCode = this.getCharCodeFromEvent(event);
		return charCode === KEY_ENTER || charCode === KEY_TAB;
	}

	pushChangesGridInput(value) {
		this.params.value = value;
		this.params.data[this.params.colDef.field] = value;
		const pushChanges: (value) => void = this.params.api['pushChangesGridInput'];
		if (pushChanges) {
			pushChanges(this.params);
		}
	}
}
