import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-permissions-header',
	templateUrl: './permissions-header.component.html',
	styleUrls: ['./permissions-header.component.scss'],
})
export class PermissionsHeaderComponent implements OnInit {
	params: any;

	constructor() {}

	ngOnInit() {}

	agInit(params): void {
		this.params = params;
	}

	onHeaderClick($event) {
		this.params.api.onHeaderClick(this.params.column.colId);
	}
}
