import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PersistentFiltersService {
	constructor() {}

	filters: { [key: string]: any } = {};

	setFilter(key, filter) {
		this.filters[key] = filter;
	}

	getFilter(key) {
		return this.filters[key];
	}

	clearFilter() {
		this.filters = {};
	}
}
