import { Action } from '@ngrx/store';

export enum PageTitleActionTypes {
	PageTitleUpdate = '[Global] Page title upload'
}

export enum PageTitleUpdateType {
	ALL,
	TITLE,
	ENTITTY,
}

export class PageTitleUpdate implements Action {
	readonly type = PageTitleActionTypes.PageTitleUpdate;

	constructor(
		public payload: {
			pageTitle?: string;
			entityName?: string;
			route?: string;
			updateType?: PageTitleUpdateType;
			applicationId?: string;
		}
	) {}
}

export type PageTitleActions = PageTitleUpdate;
