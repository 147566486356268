import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { tap } from 'rxjs/operators';
import { ActiveModeUpdate, ActiveModeActionTypes } from '../actions/active-mode.action';
import { defer } from 'rxjs';
import { ActiveType } from '../../core/enums/active-type.enum';
import { LocalStorageKeys } from '../../core/enums/local-storage-keys.enum';

@Injectable()
export class ActiveModeEffects {
	@Effect({ dispatch: false })
	activeMode$ = this.actions$.pipe(
		ofType<ActiveModeUpdate>(ActiveModeActionTypes.ActiveModeUpdate),
		tap(action => {
			localStorage.setItem(LocalStorageKeys.ACTIVE_MODE_STORAGE_KEY, JSON.stringify(action.payload.activeMode));
		})
	);

	@Effect()
	init$ = defer(() => {
		const activeMode = parseInt(localStorage.getItem(LocalStorageKeys.ACTIVE_MODE_STORAGE_KEY));
		if (!isNaN(activeMode)) {
			this.store.dispatch(new ActiveModeUpdate({ activeMode }));
		} else {
			this.store.dispatch(new ActiveModeUpdate({ activeMode: ActiveType.Active }));
		}
	});

	constructor(private actions$: Actions, private store: Store<AppState>) {}
}
