import {
	MagicReviewPageActions,
	MagicReviewPageActionTypes,
	SelectIngredientInQueue,
} from '../actions/magic-review-page.actions';
import { MagicReviewPageIngredientItem } from '../../core/models/ingredients/magic-review-page-ingredient-item.model';

export interface MagicReviewPageState {
	ingredients: MagicReviewPageIngredientItem[];
	loading: boolean;
	safeSelectIngredientIndex: number;
}

export const initialState: MagicReviewPageState = {
	ingredients: [],
	loading: false,
	safeSelectIngredientIndex: -1,
};

export function magicReviewPageReducer(state = initialState, action: MagicReviewPageActions) {
	switch (action.type) {
		case MagicReviewPageActionTypes.SetIngredients:
			return { ...state, ingredients: action.payload.ingredients };
		case MagicReviewPageActionTypes.MagicReviewPageLoading:
			return { ...state, loading: action.payload.loading };
		case MagicReviewPageActionTypes.SafeSelectIngredientInQueue:
			return { ...state, safeSelectIngredientIndex: action.payload.selectIndex };
		case MagicReviewPageActionTypes.SelectIngredientInQueue:
			const ingredients = selectIngredient(state.ingredients, action);
			return { ...state, ingredients };
		case MagicReviewPageActionTypes.RemoveCurrentlySelectedItem:
			const newlySelectedIngredients = removeSelectedIngredient(state.ingredients);
			return { ...state, newlySelectedIngredients };
		default:
			return state;
	}
}

//#region Private functions

function removeSelectedIngredient(ingredients: MagicReviewPageIngredientItem[]) {
	const selected = ingredients.find(ingredient => ingredient.selected);
	const selectedIndex = ingredients.indexOf(selected);
	ingredients.splice(selectedIndex, 1);
	if (ingredients[selectedIndex]) {
		const action = new SelectIngredientInQueue({ ingredientIndex: selectedIndex });
		ingredients = selectIngredient(ingredients, action);
	} else if (ingredients[selectedIndex - 1]) {
		const action = new SelectIngredientInQueue({ ingredientIndex: selectedIndex - 1 });
		ingredients = selectIngredient(ingredients, action);
	}
	return ingredients;
}

function selectIngredient(ingredients: MagicReviewPageIngredientItem[], action: SelectIngredientInQueue) {
	const selectedIngredient = ingredients.find(ingredient => ingredient.selected);
	const selectedIndex = ingredients.indexOf(selectedIngredient);
	if (ingredients.length > 0) {
		// handle next ingredient selection
		if (action.payload.next && selectedIndex + 1 <= ingredients.length - 1) {
			executeIngredientSelection(ingredients, selectedIndex + 1);
			return ingredients;
		}

		// handle prev ingredient selection
		if (action.payload.prev && selectedIndex - 1 >= 0) {
			executeIngredientSelection(ingredients, selectedIndex - 1);
			return ingredients;
		}

		// handle ingredient selection by index
		if (action.payload.ingredientIndex > -1 && action.payload.ingredientIndex < ingredients.length) {
			executeIngredientSelection(ingredients, action.payload.ingredientIndex);
			return ingredients;
		}
	}
	return ingredients;
}

function executeIngredientSelection(ingredients: MagicReviewPageIngredientItem[], index: number) {
	ingredients.map(ingredient => (ingredient.selected = false));
	ingredients[index].selected = true;
}
//#endregion
