export enum TabsType {
	None,
	CompanyModule,
	CompanyInfo,
	FacilityInfo,
	PrivateLabelerInfo,
	ContactInfo,
	VendorInfo,
	AgencyInfo,
	UserSettingsInfo,
	CurrentUserInfo,
	VisitsModule,
	VisitsInfo,
	TripsInfo,
	SettingsModule,
	GroupInfo,
	CustomerIngredientModule,
	MasterIngredientInfo,
	CustomerIngredientInfo,
	SubmitCustomerIngredient,
	ContractCleanup,
	IngredientBatchInfo,
	AccountingRenewal,
	MasterIngredientModule,
	Dashboard,
	TaskInfo,
	CustomerIngredientCodeChangesUtilityModuleTabs,
}
