<svg xmlns="http://www.w3.org/2000/svg" class="icon" [ngClass]="'icon ' + iconCls" viewBox="0 0 16 16">
	<path
		class="icon__path"
		d="M68.825,164.246c-.222.8-.951,1-1.825,1H64c-.874,0-1.6-.2-1.825-1H58.5v4a1,1,0,0,0,1,1h12a1,1,0,0,0,1-1v-4Z"
		transform="translate(-57.5 -153.246)"
	/>
	<path
		class="icon__path"
		d="M72.5,157.246h-3v-2a2,2,0,0,0-2-2h-4a2,2,0,0,0-2,2v2h-3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4v-.5c0-1.049.452-1.5,1.5-1.5h3c1.048,0,1.5.451,1.5,1.5v.5h4a1,1,0,0,0,1-1v-4A1,1,0,0,0,72.5,157.246Zm-5,0h-4v-2h4Z"
		transform="translate(-57.5 -153.246)"
	/>
	<rect class="icon__path" width="4" height="2" rx="0.5" transform="translate(6 9)" />
</svg>
