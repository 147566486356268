import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IngredientIdentity } from 'src/app/core/models/ingredients/ingredient-identity.model';
import {
	MasterIngredientAudit,
	MasterIngredientExtended,
	MultipleIngredients,
} from 'src/app/core/models/ingredients/master-ingredient.model';
import { KID } from 'src/app/core/models/kid.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import {
	MasterIngredientStaticBarSummary,
	MasterIngredientSummary,
} from '../../../../core/interfaces/master-ingredient-summary.interface';

@Injectable({
	providedIn: 'root',
})
export class MasterIngredientsService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	private get masterIngredientController() {
		return `${this.appConfigurationService.api.product}/masteringredient`;
	}

	load(id: number): Observable<MasterIngredientExtended> {
		return this.http.get<MasterIngredientExtended>(`${this.masterIngredientController}/load?id=${id}`);
	}

	getMasterIngredientsSummary(masterIngredientId: number) {
		return this.http.get<MasterIngredientSummary>(
			`${this.appConfigurationService.api.service}/Summary/masterIngredient?id=${masterIngredientId}`
		);
	}

	save(masterIngredient: MasterIngredientExtended) {
		return this.http.post<MasterIngredientExtended>(
			`${this.appConfigurationService.api.service}/masterIngredient/save`,
			masterIngredient
		);
	}

	saveMultipleIngredients(container: MultipleIngredients) {
		return this.http.post<MultipleIngredients>(
			`${this.appConfigurationService.api.service}/MasterIngredient/saveMultiple`,
			container
		);
	}
	getHistoryList(masterIngredientId: number): Observable<IngredientIdentity[]> {
		return this.http.get<IngredientIdentity[]>(
			`${this.masterIngredientController}/history/list?ingredientIdentityId=${masterIngredientId}`
		);
	}

	getAudit(infoIngredientIdentityId: number, compareIngredientIdentityId: number): Observable<MasterIngredientAudit> {
		return this.http.post<MasterIngredientAudit>(
			`${this.appConfigurationService.api.service}/masterIngredient/audit`,
			{
				infoIngredientIdentityId,
				compareIngredientIdentityId,
			}
		);
	}

	loadMultipleMasterIngredients(ingredientIds: number[]): Observable<MasterIngredientExtended[]> {
		return this.http.post<MasterIngredientExtended[]>(
			`${this.masterIngredientController}/LoadByIds`,
			ingredientIds
		);
	}

	generateKid(): Observable<KID[]> {
		return this.http.get<KID[]>(`${this.masterIngredientController}/identity/get/code`);
	}

	acknowledgeChangedMasterIngredients(changedMasterIngredientsIds: number[], value: boolean): Observable<any> {
		return this.http.post<any>(`${this.masterIngredientController}/change/acknowledge`, {
			ingredientIdentityChangeId: changedMasterIngredientsIds,
			value,
		});
	}

	//DEPRECATED
	reviewChangedMasterIngredients(changedMasterIngredientsIds: number[], value: boolean): Observable<any> {
		return this.http.post<any>(`${this.masterIngredientController}/change/review`, {
			ingredientIdentityChangeId: changedMasterIngredientsIds,
			value,
		});
	}

	getStaticBarSummary(id: number): Observable<MasterIngredientStaticBarSummary> {
		return this.http.get<MasterIngredientStaticBarSummary>(
			`${this.appConfigurationService.api.service}/MasterIngredient/${id}/Summary`
		);
	}
}
