import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { cloneDeep } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, filter, map, take } from 'rxjs/operators';
import {
	ExecuteBatchItemReviewAction,
	LoadMagicReviewPage,
	MagicReviewPageActionTypes,
	MagicReviewPageLoading,
	RemoveCurrentlySelectedItem,
	SelectIngredientInQueue,
	SetIngredients,
} from '../actions/magic-review-page.actions';
import { ReviewAction } from '../../core/enums/review-action.enum';
import { CustomerIngredientExtended } from '../../core/models/ingredients/customer-ingredient.model';
import { IngredientBatchReviewAction } from '../../core/models/ingredients/ingredient-batch-review-action.model';
import { MagicReviewPageIngredientItem } from '../../core/models/ingredients/magic-review-page-ingredient-item.model';
import { MasterIngredientExtended } from '../../core/models/ingredients/master-ingredient.model';
import { BatchIngredientService } from '../../core/services/batch-ingredient.service';
import { LocalStorageService } from '../../core/services/helpers/local-storage.service';
import { IngredientBatchesService } from '../../core/services/ingredient-batches.service';
import { CustomerIngredientsService } from '../../modules/ingredients/shared/services/customer-ingridients.service';
import { MasterIngredientsService } from '../../modules/master-ingredients/shared/services/master-ingredients.service';
import { AppState } from '../reducers';
import { selectIngredients, selectSelectedIngredient } from '../selectors/magic-review-page.selectors';

@Injectable()
export class MagicReviewPageEffects {
	@Effect({ dispatch: false })
	loadIngredients$ = this.actions$.pipe(
		ofType<LoadMagicReviewPage>(MagicReviewPageActionTypes.LoadMagicReviewPage),
		map(action => {
			const ids = action.payload.batchItemIds;
			this.localStorageService.magicReviewPageIds = ids;
			if (ids.length > 0) {
				this.store.dispatch(new MagicReviewPageLoading({ loading: true }));

				const observable = this.batchIngredientService.loadMultiple(ids);
				observable.subscribe(batchIngredients => {
					// get master and customer ingredients efficiently
					const miIds = [];
					const ciIds = [];
					const actions = [];
					batchIngredients.forEach(batchItem => {
						// set master ingredient ids
						if (batchItem.ingredientIdentityId && !miIds.includes(batchItem.ingredientIdentityId)) {
							miIds.push(batchItem.ingredientIdentityId);
						}
						// set customer ingredient ids
						if (batchItem.customerIngredientId && !ciIds.includes(batchItem.customerIngredientId)) {
							ciIds.push(batchItem.customerIngredientId);
						}

						if (!batchItem.reviewingById) {
							const action = new IngredientBatchReviewAction();
							action.action = ReviewAction.Review;
							action.batchIngredient = batchItem;
							actions.push(action);
						}
					});
					this.store.dispatch(
						new ExecuteBatchItemReviewAction({
							items: actions,
							action: ReviewAction.Review,
							showNotification: true,
						})
					);
					const miObservables = this.masterIngredientService.loadMultipleMasterIngredients(miIds);
					const ciObservables = this.customerIngredientService.loadMultiple(ciIds);

					forkJoin({
						customerIngredient: ciObservables,
						masterIngredients: miObservables,
					}).subscribe(res => {
						const sets: MagicReviewPageIngredientItem[] = [];
						batchIngredients.forEach(batchItem => {
							const item = new CustomerIngredientExtended();
							item.info =
								res.customerIngredient.find(ci => ci.info.id === batchItem.customerIngredientId)
									?.info ?? null;

							item.masterIngredient =
								res.masterIngredients.find(
									mi => mi.ingredientIdentity.id === batchItem.ingredientIdentityId
								) ?? null;

							const set: MagicReviewPageIngredientItem = {
								ingredient: cloneDeep(item),
								ingredientClone: cloneDeep(item),
								batchIngredient: cloneDeep(batchItem),
								batchIngredientClone: cloneDeep(batchItem),
								selected: false,
							};
							sets.push(set);
						});
						this.store.dispatch(new SetIngredients({ ingredients: sets }));
						this.store.dispatch(new MagicReviewPageLoading({ loading: false }));
						this.store.dispatch(new SelectIngredientInQueue({ ingredientIndex: 0 }));
					});
				});
			}
		})
	);

	@Effect({ dispatch: false })
	executeBatchReviewAction$ = this.actions$.pipe(
		ofType<ExecuteBatchItemReviewAction>(MagicReviewPageActionTypes.ExecuteBatchItemReviewAction),
		map(action => {
			const actionId = action.payload.action;
			switch (actionId) {
				case ReviewAction.Review:
					this.ingredientBatchesService.executeBatchItemReviewAction(action.payload.items).subscribe();
					break;
				case ReviewAction.Save:
					this.store.dispatch(new MagicReviewPageLoading({ loading: true }));
					this.ingredientBatchesService
						.executeBatchItemReviewAction(action.payload.items)
						.subscribe(actionResult => {
							this.store
								.select(selectSelectedIngredient)
								.pipe(
									filter(res => res !== undefined),
									take(1)
								)
								.subscribe(selectedIngredient => {
									if (actionResult[0].masterIngredient) {
										selectedIngredient.ingredientClone.masterIngredient = cloneDeep(
											actionResult[0].masterIngredient
										);
									}
									selectedIngredient.batchIngredient = cloneDeep(
										selectedIngredient.batchIngredientClone
									);
									selectedIngredient.ingredient = cloneDeep(selectedIngredient.ingredientClone);
									setTimeout(() => {
										this.store.dispatch(new MagicReviewPageLoading({ loading: false }));
										if (action.payload.showNotification) {
											this.notifier.notify('success', 'Your updates have been saved');
										}
									});
								});
						}),
						() => {
							this.store.dispatch(new MagicReviewPageLoading({ loading: false }));
						};
					break;
				case ReviewAction.Cancel:
					this.store.dispatch(new MagicReviewPageLoading({ loading: true }));
					const actions: IngredientBatchReviewAction[] = [];

					this.store.select(selectIngredients).subscribe(ingredients => {
						ingredients.forEach(ingredient => {
							const itemAction = new IngredientBatchReviewAction();
							itemAction.action = ReviewAction.Cancel;
							itemAction.batchIngredient = ingredient.batchIngredient;
							actions.push(itemAction);
						});
					});

					this.ingredientBatchesService.executeBatchItemReviewAction(actions).subscribe(() => {
						this.store.dispatch(new MagicReviewPageLoading({ loading: false }));
					});
					break;
				case ReviewAction.Approve:
				case ReviewAction.Reject:
					this.store.dispatch(new MagicReviewPageLoading({ loading: true }));
					const batchItemIds = action.payload.items.map(item => item.batchIngredient.id);
					this.ingredientBatchesService.executeBatchItemReviewAction(action.payload.items).subscribe(
						() => {
							const currentIds = this.localStorageService.magicReviewPageIds;
							batchItemIds.forEach(id => {
								currentIds.splice(currentIds.indexOf(id), 1);
							});
							this.localStorageService.magicReviewPageIds = currentIds;

							this.store.dispatch(new RemoveCurrentlySelectedItem({}));
							this.store.dispatch(new MagicReviewPageLoading({ loading: false }));
							if (action.payload.showNotification) {
								this.notifier.notify('success', 'Your updates have been saved');
							}
						},
						() => {
							this.store.dispatch(new MagicReviewPageLoading({ loading: false }));
						}
					);
					break;
			}
		})
	);

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private batchIngredientService: BatchIngredientService,
		private customerIngredientService: CustomerIngredientsService,
		private masterIngredientService: MasterIngredientsService,
		private ingredientBatchesService: IngredientBatchesService,
		private notifier: NotifierService,
		private localStorageService: LocalStorageService
	) {}
}
