import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-text-cell-renderer',
	templateUrl: './text-cell-renderer.component.html',
	styleUrls: ['./text-cell-renderer.component.scss'],
})
export class TextCellRendererComponent implements ICellRendererAngularComp {
	public params;
	public isCompare: boolean;
	public cssClass: string;

	constructor() {}

	public afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

	public agInit(params: ICellRendererParams): void {
		this.params = params;
		this.isCompare = this.params.colDef.isCompare;
		this.cssClass = this.params.colDef.cssClass;
	}

	public refresh(params: any): boolean {
		return false;
	}

	calculateClasses() {
		if (this.isCompare && this.params.data.oldValue !== this.params.data.newValue) {
			return this.cssClass;
		}
	}
}
