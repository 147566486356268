import { CompanyModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';
export const companyInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: '/companies/:id',
		permissionId: CompanyModuleTabs.Info,
	},
	{
		name: 'Contacts',
		path: '/companies/:id/contacts',
		permissionId: CompanyModuleTabs.Contacts,
	},
	{
		name: 'My Projects',
		path: '/companies/:id/projects',
		permissionId: CompanyModuleTabs.Facilities,
	},
	{
		name: 'Notes',
		path: '/companies/:id/notes',
		permissionId: CompanyModuleTabs.Notes,
	},
	{
		name: 'Tasks',
		path: '/companies/:id/tasks',
		permissionId: CompanyModuleTabs.Tasks,
	}
];
