import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-view-cell-renderer',
	templateUrl: './view-cell-renderer.component.html',
	styleUrls: ['./view-cell-renderer.component.scss'],
})
export class ViewCellRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;
	public hasWarning: boolean;

	agInit(params: any): void {
		this.params = params;
		this.setWarning();
	}

	refresh(): boolean {
		return false;
	}

	onViewClick() {
		const api = this.params.api as any;
		if (this.enabled()) {
			api.onViewClick(this.params);
		}
	}

	enabled() {
		return +this.params.value !== 0 || (this.params.api as any).alwaysEnableViewColumn || this.hasWarning;
	}

	setWarning(): void {
		const viewImageHasWarningColumnName = this.params.colDef['viewImageHasWarningColumnName'];

		if (viewImageHasWarningColumnName) {
			this.hasWarning = this.params.data[viewImageHasWarningColumnName] =
				this.params.data.hasMasterIngredientDiscrepancies;
		}
	}
}
