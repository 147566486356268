import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../../core/services/helpers/date-time.service';

@Pipe({
	name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
	constructor(private dateTimeService: DateTimeService) {}

	transform(value: any, format = ''): unknown {
		if (!value) {
			return null;
		}
		if (format === 'hhmm') {
			return this.dateTimeService.getFormattedTime(value);
		} else {
			return new Date(value);
		}
		if (format === 'hh:mm') {
			return this.dateTimeService.getFormattedTimeForHTML(value);
		}
	}
}
