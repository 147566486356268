import { Action } from '@ngrx/store';
import { ActiveType } from '../../core/enums/active-type.enum';

export enum ActiveModeActionTypes {
	ActiveModeUpdate = '[Global] active mode update',
}

export class ActiveModeUpdate implements Action {
	readonly type = ActiveModeActionTypes.ActiveModeUpdate;

	constructor(public payload: { activeMode: ActiveType }) {}
}

export type ActiveModeActions = ActiveModeUpdate;
