import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
	forgotPasswordForm: FormGroup;

	loading = false;
	forgotPasswordResponse = false;

	constructor(private fb: FormBuilder, private auth: AuthService, private notifier: NotifierService) {}

	ngOnInit() {
		this.forgotPasswordForm = this.fb.group({
			username: ['', [Validators.required]],
		});
	}

	get form() {
		return this.forgotPasswordForm.controls;
	}

	forgotPassword() {
		if (this.forgotPasswordForm.invalid) {
			(<any>Object).values(this.form).forEach(control => {
				control.markAsDirty();
			});
			return;
		}

		this.loading = true;
		this.auth.forgotPassword(this.form.username.value).subscribe(
			() => {
				this.loading = false;
				this.forgotPasswordResponse = true;
			},
			(errorResponse: HttpErrorResponse) => {
				this.loading = false;
				switch (errorResponse.status) {
					case 401:
						this.notifier.notify('error', errorResponse.error.message);
						break;
					default:
						this.notifier.notify('error', 'There was an error. Please contact support');
						break;
				}
			}
		);
	}
}
