import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { InputType } from 'src/app/core/enums/input-type.enum';
import { LocalStorageKeys } from 'src/app/core/enums/local-storage-keys.enum';
import { PlatformType } from 'src/app/core/enums/platform-type.enum';
import { SessionStorageKeys } from 'src/app/core/enums/session-storage-keys.enum';
import { LoginResponse } from 'src/app/core/models/auth/login.response';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { LocalStorageService } from 'src/app/core/services/helpers/local-storage.service';
import { IndexService } from 'src/app/core/services/index.service';
import { AppState } from 'src/app/store/reducers';
import { UserSettingsService } from '../../user-settings/shared/services/user-settings/user-settings.service';
import { Login } from '../../../store/actions/auth.actions';
import { AuthService } from '../auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	loginForm: FormGroup;
	isTwoFactorAuthentication = true;
	isTwoFactorAuthenticationLoading = true;
	InputType = InputType;

	loading = false;

	constructor(
		private auth: AuthService,
		private fb: FormBuilder,
		private router: Router,
		private notifier: NotifierService,
		private store: Store<AppState>,
		private localStorageService: LocalStorageService,
		private indexService: IndexService,
		private userSettingsService: UserSettingsService,
		private configurationService: ConfigurationService,
		private userIdleService: Idle
	) {}

	ngOnInit() {
		this.userIdleService.stop();
		this.loginForm = this.fb.group({
			username: ['', [Validators.required]],
			password: ['', [Validators.required]],
			pin: ['', [Validators.required]],
		});
		this.navigateToDashboard();
		this.handleLogin = this.handleLogin.bind(this);
		window.addEventListener('storage', this.handleLogin);

		this.indexService.getTwoFactorAuthentication(PlatformType.CompanyPortal).subscribe(
			response => {
				if (!response) {
					this.loginForm.controls.pin.setValidators(null);
					this.loginForm.controls.pin.updateValueAndValidity();
				}
				this.isTwoFactorAuthentication = response;
				this.isTwoFactorAuthenticationLoading = false;
			},
			() => {
				this.isTwoFactorAuthenticationLoading = false;
				this.loginForm.controls.pin.setValidators(null);
				this.loginForm.controls.pin.updateValueAndValidity();
			}
		);
	}

	ngOnDestroy() {
		window.removeEventListener('storage', this.handleLogin);
	}

	get form() {
		return this.loginForm.controls;
	}

	login() {
		if (this.loginForm.invalid) {
			(<any>Object).values(this.form).forEach(control => {
				control.markAsDirty();
			});
			return;
		}

		this.loading = true;

		this.auth.login(this.form.username.value, this.form.password.value, this.form.pin.value).subscribe(
			loginResponse => {
				if (loginResponse) {
					this.store.dispatch(new Login({ loginResponse }));
					this.userSettingsService.getCurrentUser().subscribe(
						user => {
							this.loading = false;
							if (user) {
								this.localStorageService.currentUser = user;
								this.localStorageService.userId = user.userId;
								this.navigateAfterLogin();
							}
						},
						error => this.handleErrors(error)
					);
				}
			},
			(errorResponse: HttpErrorResponse) => {
				this.handleErrors(errorResponse);
			}
		);
	}

	handleLogin(e: StorageEvent) {
		if (e.key === LocalStorageKeys.CURRENT_USER) {
			this.navigateToDashboard();
		}
	}

	navigateToDashboard() {
		const loginData = this.localStorageService.loginData;
		if (loginData) {
			this.configurationService.getServerUtcDate().subscribe(res => {
				const loginData = this.localStorageService.loginData;
				if (loginData && LoginResponse.isTokenValid(res.utcDate, loginData.expireDate)) {
					this.store.dispatch(new Login({ loginResponse: loginData }));
					this.router.navigateByUrl('/dashboard');
				}
			});
		}
	}

	handleErrors(errorResponse) {
		this.loading = false;
		switch (errorResponse.status) {
			case 401:
				this.notifier.notify('error', 'Invalid credentials. Please try again.');
				break;
			default:
				this.notifier.notify('error', 'There was an error. Please contact support.');
				break;
		}
	}

	private navigateAfterLogin() {
		const url = sessionStorage.getItem(SessionStorageKeys.NavigateAfterLogin);
		if (url) {
			this.router.navigateByUrl(url);
			sessionStorage.removeItem(SessionStorageKeys.NavigateAfterLogin);
		} else {
			this.router.navigateByUrl('/dashboard');
		}
	}
}
