import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserSettingsService } from 'src/app/modules/user-settings/shared/services/user-settings/user-settings.service';
import { PermissionModule, RightType } from '../../enums/permission-module.enum';
import { TabsType } from '../../enums/tabs-type.enum';
import { CustomRouteData } from '../../interfaces/custom-routes.interface';
import { AdvancedPermission, Permission, Right } from '../../models/permission.model';
import { LocalStorageService } from '../helpers/local-storage.service';
import { TabsService } from '../tabs.service';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	permissionsLoaded$: ReplaySubject<void> = new ReplaySubject<void>();

	currentRouteData: CustomRouteData;

	private permissions: Permission[] = [];
	private advancedPermissions: AdvancedPermission[] = [];
	private userPermissionPolling: NodeJS.Timeout;

	// Polling interval in seconds
	private pollingInterval = 60;

	canDeletePermission: { [id: number]: boolean } = {};
	canUpdatePermission: { [id: number]: boolean } = {};
	canCreatePermission: { [id: number]: boolean } = {};
	hasAdvancedPermission: { [id: number]: boolean } = {};

	constructor(
		private userSettingsService: UserSettingsService,
		private localStorageService: LocalStorageService,
		private router: Router,
		private tabsService: TabsService
	) {}

	//#region Permission methods
	canView(permissionIds: number[], isModule = false): boolean {
		if (permissionIds?.length > 0) {
			const subModules = [];
			permissionIds.forEach(id => {
				const item = this.getPermissionItem(id, isModule);
				subModules.push(item);
			});
			const rightValues = [];
			subModules.forEach(subModule => {
				rightValues.push(this.getRightValue(subModule, RightType.View));
			});
			return rightValues.some(value => value === true);
		}
		return true;
	}

	get canCreate(): boolean {
		const currentPermission = this?.currentRouteData.permissionId;
		return this?.canCreatePermission[currentPermission];
	}

	get canUpdate(): boolean {
		const currentPermission = this.currentRouteData.permissionId;
		return this.canUpdatePermission[currentPermission];
	}

	get canDelete(): boolean {
		const currentPermission = this.currentRouteData.permissionId;
		return this.canDeletePermission[currentPermission];
	}

	canExport(permissionId: number, isModule = false): boolean {
		const permissionItem = this.getPermissionItem(permissionId, isModule);
		return this.getRightValue(permissionItem, RightType.Export);
	}

	canActivate(permissionId: number, isModule = false): boolean {
		const permissionItem = this.getPermissionItem(permissionId, isModule);
		return this.getRightValue(permissionItem, RightType.Activate);
	}
	//#endregion

	//#region Data Management methods
	getCurrentUserData() {
		const permissions = this.localStorageService.userPermissions;
		if (permissions) {
			this.permissions = permissions;
		}

		const advancedPermissions = this.localStorageService.userAdvancedPermissions;
		if (advancedPermissions) {
			this.advancedPermissions = advancedPermissions;
		}

		this.loadPermissions();
	}

	startPermissionPolling() {
		this.stopPermissionPolling();
		this.userPermissionPolling = setInterval(() => {
			if (!this.localStorageService.idle) {
				this.getCurrentUserData();
			}
		}, this.pollingInterval * 1000);
	}

	stopPermissionPolling() {
		if (this.userPermissionPolling) {
			clearInterval(this.userPermissionPolling);
		}
	}

	setPermissions(permissions: Permission[]) {
		this.permissions = permissions;
	}
	//#endregion

	//#region Helper methods
	isAnyTabViewable(type: TabsType): boolean {
		const tabs = this.tabsService.getTabs(type);
		if (tabs.length > 0) {
			return tabs.some(tab => this.canView([tab.permissionId], tab.isModule));
		}
		return false;
	}

	checkCurrentRoutePermission() {
		if (this.currentRouteData) {
			switch (false) {
				case this.canView([this.currentRouteData?.permissionId], this.currentRouteData?.isModule):
					this.router.navigate(['/forbidden']);
					break;
			}
		}
	}

	checkPermissions(): void {
		this.permissions.forEach(p => {
			this.setPermissionsDictionary(p);

			p.items.forEach(i => {
				this.setPermissionsDictionary(i);
			});
		});

		this.advancedPermissions.forEach(permission => {
			this.hasAdvancedPermission[permission.advancedPermissionId] = permission.value;
		});
	}

	//#endregion

	//#region Private Methods

	private setPermissionsDictionary(permission: Permission) {
		this.canDeletePermission[permission.permissionEntity.id] = this.getRightByType(
			permission.rights,
			RightType.Delete
		).value;

		this.canUpdatePermission[permission.permissionEntity.id] = this.getRightByType(
			permission.rights,
			RightType.Update
		).value;

		this.canCreatePermission[permission.permissionEntity.id] = this.getRightByType(
			permission.rights,
			RightType.Create
		).value;
	}

	private loadPermissions() {
		const userId = this.localStorageService.userId;
		if (userId) {
			const permissionsObservables = [this.loadGeneralPermissions(userId), this.loadAdvancedPermissions(userId)];
			forkJoin(permissionsObservables).subscribe(() => {
				this.checkCurrentRoutePermission();
				this.checkPermissions();
				this.permissionsLoaded$.next();
			});
		}
	}

	private loadGeneralPermissions(userId: number): Observable<Permission[]> {
		return this.userSettingsService.getUserPermissions(userId).pipe(
			tap(permissions => {
				if (permissions) {
					this.permissions = permissions;
					this.localStorageService.userPermissions = this.permissions;
				}
			})
		);
	}

	private loadAdvancedPermissions(userId: number): Observable<AdvancedPermission[]> {
		return this.userSettingsService.getUserAdvancedPermissions(userId).pipe(
			tap(advancedPermissions => {
				if (advancedPermissions) {
					this.advancedPermissions = advancedPermissions;
					this.localStorageService.userAdvancedPermissions = this.advancedPermissions;
				}
			})
		);
	}

	private getRightValue(permission: Permission, rightType: RightType): boolean {
		if (permission) {
			const right = this.getRightByType(permission.rights, rightType);
			if (right) {
				return right.value;
			}
			return true;
		}
		return true;
	}

	private getPermissionItem(permissionId: number, isModule = false): Permission {
		if (isModule) {
			return this.getModule(permissionId);
		} else {
			return this.getModulePermission(permissionId);
		}
	}

	private getModule(moduleId: PermissionModule): Permission {
		const module = this.permissions.find(p => p.permissionEntity.id === moduleId);
		return module;
	}

	private getModulePermission(permissionId: number): Permission {
		const module = this.permissions.find(p => p.items.some(i => i.permissionEntity.id === permissionId));
		return module?.items?.find(i => i.permissionEntity.id === permissionId);
	}

	private getRightByType(rights: Right[], type: RightType): Right {
		return rights.find(r => r.permissionRightId === type);
	}
	//#endregion
}
