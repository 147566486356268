import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class UrlHelperService {
	constructor() {}

	urlsMatch(currentUrl: string, urlToCompare: string) {
		const currentUrlSubsets = currentUrl.split('/');
		const urlToCompareSubsets = urlToCompare.split('/');
		if (urlToCompareSubsets.some((subset: string) => subset.includes('**'))) {
			const anyIndex = urlToCompareSubsets.findIndex(val => val.includes('**'));
			const indexes = [];
			for (let i = 0; i < anyIndex + 1; i++) {
				indexes.push(i);
			}
			return indexes.every(i => {
				if (urlToCompareSubsets[i].includes('**')) {
					return true;
				}
				if (urlToCompareSubsets[i].includes(':') && currentUrlSubsets[i]) {
					return true;
				}
				return currentUrlSubsets[i] === urlToCompareSubsets[i];
			});
		} else if (currentUrlSubsets?.length === urlToCompareSubsets?.length) {
			const indexes = [];
			for (let i = 0; i < currentUrlSubsets.length; i++) {
				indexes.push(i);
			}
			return indexes.every(i => {
				if (urlToCompareSubsets[i].includes(':') && currentUrlSubsets[i]) {
					return true;
				}
				return currentUrlSubsets[i] === urlToCompareSubsets[i];
			});
		}
		return false;
	}

	/** The url param must be written by the angular route standard
	 *
	 * This means that if you want to replace a part of the url with a number
	 * it must pe prefixed with ':' .
	 *
	 * Example : /entity/:id/info
	 */
	replaceIdInUrl(url: string, id: number | string) {
		const params = url.split('/');
		url = '';
		params.forEach(param => {
			if (param !== '') {
				if (param.includes(':')) {
					url += '/' + id;
				} else {
					url += '/' + param;
				}
			}
		});
		return url;
	}

	addQueryParam(params: { [id: string]: string | number }, value: any, key: string, required = true) {
		if (required || (!required && value)) {
			params[key] = value;
		}
		return params;
	}
}
