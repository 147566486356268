import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { TabsActionTypes, TabsLoaded, TabsRequested } from '../actions/tabs.actions';
import { TabsService } from '../../core/services/tabs.service';
import { AppState } from '../reducers';

@Injectable()
export class TabsEffects {
	@Effect()
	loadTabs$ = this.actions$.pipe(
		ofType<TabsRequested>(TabsActionTypes.TabsRequested),
		withLatestFrom(this.store.select(state => state.tabs)),
		switchMap(([action, tabs]) => {
			return this.tabsService.getTabsObservable(action.payload.type, tabs.entityId);
		}),
		map(tabs => new TabsLoaded({ tabs }))
	);

	constructor(private actions$: Actions, private tabsService: TabsService, private store: Store<AppState>) {}
}
