import { MasterIngredientExtended } from './master-ingredient.model';
import { EntityAttribute } from '../entity-attribute.model';
import { EntityHistory } from '../../models/history.model';

export class CustomerIngredient {
	id: number;
	customerKey: any;
	dkIngredientId: number;
	effectiveExpirationDate: string;
	ingredientIdentityId: number;
	ingredientOwnerId: number;
	ownerId: number;
	ingredientSourceId: number;
	ingredientStatusId: number;
	ingredientTypeId: number;
	key: any;
	name: string;
	rmc: string;
	submittedDate: string;
	systemRMC: string;
	vendorId: number;
	vendorName: string;
	attributes: EntityAttribute[];
	history: EntityHistory;
	distributor: string;
	packageTypeId: number;
	estimatedRestockDate?: Date | string;
	purchaseDate?: Date | string;
	lotNumber?: string;
}

export class CustomerIngredientExtended {
	info: CustomerIngredient;
	masterIngredient: MasterIngredientExtended;
	systemRMC?: string;
}
