<div class="dropdown-wrapper">
	<ng-select
		#input
		(keydown)="onKeyDown($event)"
		[items]="params.colDef.dropdownOptions"
		bindLabel="name"
		bindValue="id"
		appendTo=".ag-root-wrapper"
		[formControl]="selectedOption"
		(change)="pushDropdownInput()"
		(click)="isDropdownOpened(true)"
		(blur)="isDropdownOpened(false)"
	>
	</ng-select>
</div>
