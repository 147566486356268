import { Component, DoCheck } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { CellRendererCustomParams } from 'src/app/core/models/cell-renderer-custom-params.model';

@Component({
	selector: 'app-check-header-renderer',
	templateUrl: './check-header-renderer.component.html',
	styleUrls: ['./check-header-renderer.component.scss'],
})
export class CheckHeaderRendererComponent implements IHeaderAngularComp, DoCheck {
	public params: any;
	selected = false;
	allowSelection = true;

	constructor() {}

	public agInit(params: any): void {
		this.params = params;
	}

	ngDoCheck() {
		this.checkSelectedStatus();
	}

	checkSelectedStatus(): void {
		if (this.params.api !== undefined) {
			const nodes = [];
			this.params.api.forEachNode(node => {
				if (node.data) {
					nodes.push(node);
				}
			});
			if (nodes.length > 0) {
				if (
					nodes.every(
						node => node?.data?.allowSelection === false || node?.customParams?.selected?.editable === false
					)
				) {
					this.allowSelection = false;
				}
				this.selected = nodes.every(node => {
					if (this.nodeSelectable(node)) {
						return node.data[this.params.column.colId] === true;
					} else {
						return true;
					}
				});
			}
		}
	}

	onCheck(value): void {
		const nodeData = [];
		this.params.api.forEachNode(node => {
			if (node.data.allowSelection === undefined || node.data.allowSelection === true) {
				if (node.data && node.data[this.params.column.colId] !== value) {
					if (this.nodeSelectable(node)) {
						node.data[this.params.column.colId] = value;
					}
				}
				nodeData.push(node.data);
			}
		});
		this.params.api.refreshCells();
		this.params.api.onHeaderCheck(nodeData);
		this.params.api.onRowsSelectionChanged(nodeData);
		setTimeout(() => {
			this.params.api.adjustColumnWidthByKey(this.params.column.colId);
		});
	}

	onViewSelection() {
		this.params.api.onViewSelection();
	}

	refresh(params: IHeaderParams): boolean {
		return false;
	}

	nodeSelectable(node) {
		const customParams = this.nodeCustomParams(node);
		return customParams.editable === true && !customParams.warning && !customParams.disabled;
	}

	nodeCustomParams(node): CellRendererCustomParams {
		const defaultParams = new CellRendererCustomParams();
		defaultParams.editable = true;

		if (!!node.customParams) {
			const customParams = node.customParams[this.params.column.colId];
			if (!customParams || Object.keys(customParams).length === 0) {
				return defaultParams;
			}
			return customParams;
		}
		return defaultParams;
	}
}
