import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

@Directive()
export class InputBase {
	componentId = uuid.v4();
	/** Model propriety, can be any primitive data type */
	private _model: any = this.componentId;
	@Input() set model(value: any) {
		if (value !== undefined) {
			if (this.field) {
				this.field.patchValue(value);
			}
		}
		if (this.autoValidate) {
			this.validate();
		}
		this._model = value;
	}
	get model() {
		return this._model;
	}

	debugMode = environment?.debugMode;

	@Output() modelChange = new EventEmitter<any>();

	@Input() field: AbstractControl;
	@Output() fieldChange = new EventEmitter<AbstractControl>();
	@Output() onBlur = new EventEmitter<any>();
	@Output() focus = new EventEmitter<any>();

	private _disabled: boolean;
	/** Input disabled propriety */
	@Input() set disabled(value: boolean) {
		this._disabled = value;
		this.setDisabledStatus();
	}
	get disabled() {
		return this._disabled;
	}

	private _required;
	/** Required input, dynamically sets the required validator of the input */
	@Input() set required(value: boolean) {
		this._required = value;
		if (this.field) {
			this.setInputValidators();
		}
	}
	get required() {
		return this._required;
	}

	private _readonly = false;
	/** Forces a disabled status on the input */
	@Input() set readonly(value: boolean) {
		this._readonly = value;
		this.setDisabledStatus();
	}
	get readonly() {
		return this._readonly;
	}

	/** Validates the input without the need for the user to touch it */
	@Input() autoValidate = false;

	@Input() inputCls: string;
	@Input() details: string;
	@Input() label: string;
	@Input() size: string;
	@Input() placeholder = '';
	@Input() blurOnEnter = false;

	/** To achieve dynamic validator change use this prop */
	@Input() validators: ValidatorFn[];
	@Input() customValidatorMessage: string;

	@Output() enter = new EventEmitter();

	@Input() originalValue;
	@Input() compareToOriginalValue;

	@ViewChild('inputRef') inputRef: ElementRef;

	@HostListener('keyup.enter', ['$event'])
	handleKeyUp(e: KeyboardEvent) {
		if (this.blurOnEnter) {
			const element = document.getElementById(this.componentId);
			if (element && document.activeElement.id === element.id) {
				if (this.inputRef?.nativeElement) {
					e.preventDefault();
					e.stopImmediatePropagation();
					this.executeBlurOnEnter(this.inputRef.nativeElement);
				}
			}
		}
	}

	initInput() {
		this.setField();
		this.setDisabledStatus();
		this.setInputValidators();

		if (this.autoValidate) {
			this.validate();
		}

		if (this.model === this.componentId) {
			this.model = undefined;
		}
	}

	focusChanges() {
		this.focus.emit(this.field?.value);
	}

	emitChange() {
		this.modelChange.emit(this.field?.value);
	}

	blurChanges() {
		this.onBlur.emit(this.field?.value);
	}

	emitCustomChange(value: any) {
		this.modelChange.emit(value);
	}

	executeBlurOnEnter(element) {
		if (element && element.blur && this.blurOnEnter) {
			setTimeout(() => {
				element.blur();
			}, 100);
		}
	}

	validate() {
		if (this.field) {
			this.field.markAsDirty();
			this.field.markAsTouched();
			this.field.updateValueAndValidity();
		}
	}

	revertToOriginalValue() {
		if (this.fieldValueChanged()) {
			this.field.patchValue(this.originalValue);
			this.field.markAsUntouched();
			this.field.markAsPristine();
			this.emitChange();
		}
	}

	isParentFieldSetDisabled(ref) {
		if (ref) {
			if (ref.nodeName === 'FIELDSET') {
				return ref.disabled;
			} else {
				return this.isParentFieldSetDisabled(ref.parentNode);
			}
		}
		return false;
	}

	clearInput() {
		this.field.patchValue(null);
		this.field.markAsUntouched();
		this.field.markAsPristine();
	}

	get showFieldErrors(): boolean {
		return this.field && (this.field.touched || this.field.dirty) && !!this.field.errors;
	}

	get fieldInvalid(): boolean {
		if (this.field) {
			return this.field && (this.field.touched || this.field.dirty) && this.field.invalid;
		}
		return false;
	}

	get fieldChanged() {
		if (this.field && (this.field.dirty || this.field.touched)) {
			return this.fieldValueChanged();
		}
		return false;
	}

	get fieldEmpty() {
		if (!this.disabled) {
			return !this.field.value || String(this.field.value).trim().length === 0;
		}
		return false;
	}

	get hasRequiredValidator() {
		if (this.field && this.field.validator) {
			const validator = this.field.validator({} as AbstractControl);
			if (validator && validator.required) {
				return true;
			}
		}
		return false;
	}

	setInputValidators() {
		if (this.field !== undefined) {
			if (this.validators && this.validators.length > 0) {
				this.field.setValidators(this.validators);
			}
			if (this.required === true) {
				this.field.addValidators(Validators.required);
			} else if (this.required === false) {
				this.field.removeValidators(Validators.required);
			}
			this.field.updateValueAndValidity();
		}
	}

	/** checks if the raw value of the field changed regardless if the user touched it or not */
	private fieldValueChanged() {
		if (this.originalValue !== undefined) {
			if (this.compareToOriginalValue !== undefined) {
				return this.valueChanged(this.compareToOriginalValue, this.originalValue);
			}
			return this.valueChanged(this.field.value, this.originalValue);
		}
		return false;
	}

	private setField() {
		if (this.model !== this.componentId) {
			this.field = new FormControl();
			this.field.setValue(this.model);
		}
		if (this.model === this.componentId && this.field === undefined) {
			this.field = new FormControl();
		}
	}

	private setDisabledStatus() {
		if (this.field) {
			if (this.disabled) {
				this.field.disable();
			} else {
				this.field.enable();
			}
		}
	}

	private valueChanged(valueA, valueB) {
		let originalVal = valueA;
		let newVal = valueB;
		if (this.falseyValue(originalVal) && this.falseyValue(newVal)) {
			return false;
		}

		if (typeof originalVal !== 'string') {
			originalVal = String(originalVal);
		}

		if (typeof newVal !== 'string') {
			newVal = String(newVal);
		}
		return originalVal !== newVal;
	}

	// checks if value is falsey except for boolean values
	private falseyValue(value: any): boolean {
		if (String(value).length === 0) {
			return true;
		}
		if (value == false || value == true) {
			return false;
		}
		return !Boolean(value);
	}
}
