import { FindFilterType } from '../../enums/find-filter-type.enum';

export class SearchCriteria {
	keyword: string;
	maxCount: number;
	excludedIds: number[];

	constructor(keyword: string, maxCount: number, excludedIds: number[]) {
		this.keyword = keyword || '';
		this.maxCount = maxCount;
		this.excludedIds = excludedIds;
	}
}

export class FindSearchCriteria {
	keyword: string;
	maxCount: number;
	excludedIds: number[];
	entityTypeId: number;
	businessEntityTypeId: number;
	filterType: FindFilterType;
	hasUser? = null;
	isParentVendor?: boolean;
	constructor(
		keyword: string,
		maxCount: number,
		entityTypeId: number,
		excludedIds: number[],
		businessEntityTypeId?: number,
		hasUser?: boolean,
		isParentVendor?: boolean
	) {
		this.keyword = keyword;
		if (keyword && !Number.isNaN(+keyword)) {
			this.filterType = FindFilterType.IdEquals;
		} else {
			this.filterType = FindFilterType.BeginsWith;
		}
		this.maxCount = maxCount;
		this.excludedIds = excludedIds;
		this.entityTypeId = entityTypeId;
		this.businessEntityTypeId = businessEntityTypeId;
		this.hasUser = hasUser;
		this.isParentVendor = isParentVendor;
	}
}
