<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="iconCls" viewBox="0 0 12 12" (click)="openLinkInNewTab()">
	<path
		class="icon__path"
		d="M13.175,0h-4.5c-.21,0-.255.12-.113.262l1.62,1.62L6.313,5.738a.371.371,0,0,0,0,.532l.967.968a.385.385,0,0,0,.533.008l3.938-3.78,1.523,1.53c.158.135.278.09.278-.12V.375A.371.371,0,0,0,13.175,0Z"
		transform="translate(-1.55)"
	/>
	<path
		class="icon__path"
		d="M9,12.25H2.25A2.257,2.257,0,0,1,0,10V3.25A2.257,2.257,0,0,1,2.25,1H4.815a.75.75,0,0,1,0,1.5H2.25a.752.752,0,0,0-.75.75V10a.752.752,0,0,0,.75.75H9A.752.752,0,0,0,9.75,10V7.487a.75.75,0,0,1,1.5,0V10A2.257,2.257,0,0,1,9,12.25Z"
		transform="translate(0 -0.25)"
	/>
</svg>
