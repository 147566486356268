import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SimpleModalService } from 'ngx-simple-modal';
import { tap } from 'rxjs/operators';
import { CurrencyLoad, UserLogOut } from 'src/app/store/actions/global.action';
import { SideInfoToggle } from 'src/app/store/actions/side-info.action';
import { LocalStorageService } from 'src/app/core/services/helpers/local-storage.service';
import { PersistentFiltersService } from 'src/app/core/services/persistent-filters.service';
import { AppState } from 'src/app/store/reducers';
import { AuthActionTypes, Login, Logout } from '../actions/auth.actions';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { LogoutRequest } from 'src/app/core/models/auth/logout-request.model';
import { PlatformType } from 'src/app/core/enums/platform-type.enum';

@Injectable()
export class AuthEffects {
	login$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType<Login>(AuthActionTypes.LoginAction),
				tap(action => {
					this.localStorageService.loginData = action.payload.loginResponse;
					this.store.dispatch(new CurrencyLoad({}));
				})
			);
		},
		{ dispatch: false }
	);

	logout$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType<Logout>(AuthActionTypes.LogoutAction),
				tap(() => {
					const logoutRequest = new LogoutRequest();
					logoutRequest.refreshToken = this.localStorageService.loginData.refreshToken;
					logoutRequest.platform = PlatformType.CompanyPortal;
					this.authService.logout(logoutRequest).subscribe();

					this.store.dispatch(new UserLogOut());
					setTimeout(() => {
						this.localStorageService.clearLocalStorage();
						this.localStorageService.idle = false;
						this.simpleModalService.removeAll();
						this.userIdleService.stop();
						this.persistentFiltersService.clearFilter();
						this.store.dispatch(new SideInfoToggle({ visible: false }));
						setTimeout(() => {
							this.router.navigateByUrl('/login');
						});
					}, 100);
				})
			);
		},
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private router: Router,
		private store: Store<AppState>,
		private localStorageService: LocalStorageService,
		private simpleModalService: SimpleModalService,
		private userIdleService: Idle,
		private persistentFiltersService: PersistentFiltersService,
		private authService: AuthService
	) {}
}
