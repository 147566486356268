<svg
	xmlns="http://www.w3.org/2000/svg"
	[ngClass]="'icon ' + iconCls"
	width="14"
	height="14.287"
	viewBox="0 0 14 14.287"
>
	<path
		class="a"
		d="M1.9,19.263a7.1,7.1,0,0,0-.787-1.351C1.039,17.8.961,17.7.883,17.6A7.321,7.321,0,0,0-.705,16.164c-.145-.093-.293-.189-.445-.275a7.289,7.289,0,0,0-1.518-.635c-.126-.037-.256-.074-.382-.1A6.727,6.727,0,0,0-4.4,14.98c-.067,0-.13-.011-.2-.011h-.026l-.03,0-.1,0a7.27,7.27,0,0,0-1.236.126c-.1.019-.208.048-.315.078a7.212,7.212,0,0,0-1.143.371c-.1.045-.2.093-.3.137a7.69,7.69,0,0,0-1.054.62c-.089.067-.182.13-.271.2a6.336,6.336,0,0,0-.56.486l-.869-.883-.549-.557-.048-.045a.235.235,0,0,0-.26-.052.25.25,0,0,0-.156.223l.2,4.086.033.828.007.152h4.773l.056-.015a.242.242,0,0,0,.189-.234A.243.243,0,0,0-6.3,20.35a.255.255,0,0,0-.071-.071l-.182-.178-.932-.95-.249-.256-.082-.078c.052-.048.1-.089.16-.137a4.784,4.784,0,0,1,.483-.375,4.583,4.583,0,0,1,1.522-.65c.119-.026.245-.045.371-.063a4.855,4.855,0,0,1,.661-.052,4.231,4.231,0,0,1,.62.052q.167.022.323.056a4.6,4.6,0,0,1,1.132.416c.093.048.189.1.282.163a4.739,4.739,0,0,1,.984.817c.082.093.163.189.238.29a4.633,4.633,0,0,1,.75,1.44,4.184,4.184,0,0,1,.134.575,4.6,4.6,0,0,1,.071.791A4.192,4.192,0,0,1-.253,23.3a3.791,3.791,0,0,1-.163.49,4.5,4.5,0,0,1-.8,1.3q-.117.139-.245.267a4.7,4.7,0,0,1-1.006.742c-.1.052-.2.1-.3.145a4.712,4.712,0,0,1-1.151.353c-.108.019-.219.03-.327.041-.137.015-.271.022-.412.026a4.546,4.546,0,0,1-.92-.156c-.134-.037-.264-.071-.39-.119a4.591,4.591,0,0,1-1.674-1.062,4.706,4.706,0,0,1-.624-.753,4.9,4.9,0,0,1-.33-.6l-1,.464-.293.134-1.047.483.015.03a8.551,8.551,0,0,0,2.09,2.683c.085.074.178.141.267.212a6.546,6.546,0,0,0,1.043.646l.3.134a6.267,6.267,0,0,0,1.151.36c.1.022.212.037.323.052a6.443,6.443,0,0,0,1.258.071c.119,0,.234-.011.353-.026h.059a7.278,7.278,0,0,0,1.31-.234c.134-.037.264-.082.393-.126a7.267,7.267,0,0,0,1.559-.75c.156-.1.312-.2.464-.319A7.137,7.137,0,0,0,.7,26.8a3.46,3.46,0,0,0,.241-.282,7.036,7.036,0,0,0,.809-1.28c.071-.137.134-.275.193-.416a7.345,7.345,0,0,0,.52-2.071c.022-.212.03-.431.033-.646l0-.052a6.98,6.98,0,0,0-.419-2.338A4.824,4.824,0,0,0,1.9,19.263Z"
		transform="translate(11.506 -14.965)"
	/>
</svg>
