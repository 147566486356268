import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { NavigationHistoryService } from 'src/app/core/services/navigation-history.service';
import { SideInfoToggle } from './store/actions/side-info.action';
import { EntityUpdate, TabsLoaded, TabsRequested } from './store/actions/tabs.actions';
import { EntityType } from './core/enums/entity-type.enum';
import { TabsType } from './core/enums/tabs-type.enum';
import { CustomRouteData } from './core/interfaces/custom-routes.interface';
import { PageTitle } from './core/models/general/page-title.model';
import { PermissionService } from './core/services/communication/permission.service';
import { EntityUrlHelperService } from './core/services/helpers/entity-url-helper.service';
import { LocalStorageService } from './core/services/helpers/local-storage.service';
import { UrlHelperService } from './core/services/helpers/url-helper.service';
import { IndexService } from './core/services/index.service';
import { TabsService } from './core/services/tabs.service';
import { Login } from './store/actions/auth.actions';
import { AppState } from './store/reducers';
import { selectTabs } from './store/selectors/tabs.selectors';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	tabsType: number = null;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store<AppState>,
		private indexSvc: IndexService,
		private navigationHistoryService: NavigationHistoryService,
		private tabTitle: Title,
		private localStorageService: LocalStorageService,
		private urlHelperService: UrlHelperService,
		private permissionService: PermissionService,
		private entityUrlHelper: EntityUrlHelperService,
		private tabsService: TabsService
	) {
		pdfDefaultOptions.assetsFolder = 'bleeding-edge';
	}

	ngOnInit() {
		if (this.localStorageService.userPermissions) {
			this.permissionService.setPermissions(this.localStorageService.userPermissions);
		}
		this.checkUserLoginStatus();

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => {
					let route = this.activatedRoute.firstChild;
					let child = route;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
							route = child;
						} else {
							child = null;
						}
					}
					return route;
				}),
				filter(route => route !== null),
				mergeMap(
					route => route.data,
					(route, data) => {
						return [route.params, data];
					}
				)
			)
			.subscribe(([route, data]) => {
				this.permissionService.currentRouteData = data;
				this.permissionService.checkCurrentRoutePermission();

				this.store.dispatch(
					new EntityUpdate({
						entityId: route.value.id,
						companyId: route.value.companyId,
						facilityId: route.value.facilityId,
					})
				);

				this.store.dispatch(new SideInfoToggle({ visible: false }));

				const entityId = +route.value.id;

				if (Object.keys(route.value).length !== 0) {
					switch (data.tabsType) {
						case TabsType.CustomerIngredientInfo:
							this.setEntityPageTitle(entityId, EntityType.IngredientInfo, data);
							break;
						case TabsType.IngredientBatchInfo:
							this.setEntityPageTitle(entityId, EntityType.BatchIngredient, data);
							break;
						case TabsType.MasterIngredientInfo:
							this.setEntityPageTitle(entityId, EntityType.K_ID, data);
							break;
						case TabsType.VisitsInfo:
							this.setEntityPageTitle(entityId, EntityType.Visit, data);
							break;
						case TabsType.TripsInfo:
							this.setEntityPageTitle(entityId, EntityType.Visit, data);
							break;
						case TabsType.ContactInfo:
							this.setEntityPageTitle(entityId, EntityType.ContactDetail, data);
							break;
						case TabsType.UserSettingsInfo:
						case TabsType.CurrentUserInfo:
							this.setEntityPageTitle(entityId, EntityType.User, data);
							break;
						case TabsType.TaskInfo:
							this.setEntityPageTitle(entityId, EntityType.Task, data);
							break;
						case TabsType.SubmitCustomerIngredient:
							this.setBatchItemPageTitle(data, route.value);
							break;
						case TabsType.CustomerIngredientCodeChangesUtilityModuleTabs:
							this.setCodeChangeUtilityPageTitle(
								'Code Change Utility',
								entityId,
								data,
								data.route,
								EntityType.CodeChangeUtility
							);
							break;
						case TabsType.None:
							switch (data.entityType) {
								case EntityType.Application:
									this.setApplicationMergePageTitle(entityId, data);
									break;
								case EntityType.KosherCertificate:
									this.setEntityPageTitle(entityId, EntityType.KosherCertificate, data);
									break;
								case EntityType.Amex:
									this.setEntityPageTitle(entityId, EntityType.Amex, data);
									break;
								case EntityType.BatchProductHeader:
									this.getBatchProductPageTitle(route, data, entityId);
									break;
								case EntityType.BatchIngredientItem:
									const batchItemId = route.value.bachIngredientId;
									if (batchItemId) {
										this.setBatchItemPageTitle(data, route.value);
									}
									break;
							}
							break;
						default:
							const isConfirm = this.urlHelperService.urlsMatch(this.router.url, '/confirm-account/:key');
							if (!isConfirm) {
								if (entityId) {
									this.getBusinessEntityPageTitle(data, entityId);
								} else {
									this.setDefaultPageTitle(data);
								}
							}
							break;
					}
				} else {
					this.setDefaultPageTitle(data);
				}

				if (!this.tabsType || this.tabsType !== data.tabsType) {
					this.store.dispatch(new TabsRequested({ type: data.tabsType }));
					this.setTabsNotificationIcon();
				}

				this.setPageTitle();

				this.tabsType = data.tabsType;
			});
	}

	private checkUserLoginStatus() {
		const loginData = this.localStorageService.loginData;
		if (loginData) {
			this.store.dispatch(new Login({ loginResponse: loginData }));
		}
	}

	private getBatchProductPageTitle(route, data, entityId) {
		this.indexSvc.getEntitySummary(+route.value.id, EntityType.BatchProductHeader).subscribe((response: any) => {
			if (response) {
				const url = `${data.route}/${+route.value.id}/add`;
				this.setNavigationHistory(response.name, entityId, data, url, EntityType.BatchProductHeader);
			}
		});
	}

	private getBusinessEntityPageTitle(data: CustomRouteData, entityId: number) {
		this.indexSvc.getBusinessEntitySummary(entityId).subscribe(entitySummary => {
			if (entitySummary) {
				let url = `${data.route}/${entityId}`;
				if (data.route) {
					url = `${data.route}/${entityId}`;
				} else {
					url = this.router.url;
				}
				this.setNavigationHistory(entitySummary.name, entityId, data, url, -1);
			}
		});
	}

	private setEntityPageTitle(entityId: number, entityTypeId: EntityType, data: CustomRouteData): void {
		this.indexSvc.getEntitySummary(entityId, entityTypeId).subscribe((response: any) => {
			if (response) {
				const route = `${data.route}/${entityId}`;
				this.setNavigationHistory(response.name, entityId, data, route, entityTypeId);
			}
		});
	}

	private setPageTitle() {
		const filteredNavigationHistory = this.navigationHistoryService?.getFilteredNavigationHistory();

		if (filteredNavigationHistory?.length > 0) {
			const lastNavigationHistory = filteredNavigationHistory[filteredNavigationHistory.length - 1];
			this.tabTitle.setTitle(
				lastNavigationHistory.entityName
					? lastNavigationHistory.pageTitle + ' ' + lastNavigationHistory.entityName
					: lastNavigationHistory.pageTitle
					? lastNavigationHistory.pageTitle
					: 'KNM'
			);
		}
	}

	private setApplicationPageTitle(applicationId: number, data: CustomRouteData) {
		this.indexSvc.getApplicationSummary(applicationId).subscribe(entitySummary => {
			if (entitySummary) {
				this.setNavigationHistory(entitySummary.name, applicationId, data, null, EntityType.Application);
			}
		});
	}

	private setApplicationMergePageTitle(applicationId: number, data: CustomRouteData) {
		this.indexSvc.getApplicationSummary(applicationId).subscribe(entitySummary => {
			if (entitySummary) {
				let route = `${data.route}/${applicationId}/${entitySummary.typeId}`;
				this.setNavigationHistory(entitySummary.name, applicationId, data, route, EntityType.Application);
			}
		});
	}

	private setBatchItemPageTitle(data: CustomRouteData, routeValue) {
		const itemId = +routeValue.bachIngredientId;
		const headerId = +routeValue.id;

		if (data.tabsType === TabsType.None) {
			this.indexSvc.getEntitySummary(itemId, EntityType.BatchIngredientItem).subscribe((response: any) => {
				if (response) {
					const route = `${data.route}/${headerId}/${itemId}`;
					this.setNavigationHistory(response.name, itemId, data, route, EntityType.BatchIngredientItem);
				}
			});
		} else if (data.tabsType === TabsType.SubmitCustomerIngredient) {
			this.indexSvc.getEntitySummary(headerId, EntityType.BatchIngredient).subscribe((response: any) => {
				if (response) {
					data.tabsType = TabsType.None;

					const entityId = response.entityId;
					const route = `${data.route}/${entityId}`;
					this.setNavigationHistory(response.name, entityId, data, route, EntityType.BatchIngredient);
				}
			});
		}
	}

	private setNavigationHistory(
		name: string,
		entityId: number | string,
		data: CustomRouteData,
		route: string,
		entityTypeId: number,
		isCustomRoute = false
	) {
		name ??= '';
		const entityName = entityId ? `#${entityId} ${name}` : `${name}`;

		const pageTitle = new PageTitle();
		pageTitle.pageTitle = data.pageTitle;
		pageTitle.entityName = entityName;
		pageTitle.entityId = entityId;
		pageTitle.entityTypeId = entityTypeId;

		if (data.tabsType && +data.tabsType !== TabsType.None && !isCustomRoute) {
			pageTitle.route = this.entityUrlHelper.getEntityUrlByTabType(data.tabsType, entityId);
		} else {
			pageTitle.route = route;
		}

		this.navigationHistoryService.addNavigationHistory(pageTitle);
		this.setPageTitle();
	}

	private setDefaultPageTitle(data: CustomRouteData) {
		const pageTitle = {
			pageTitle: data.pageTitle,
			route: data.route ? data.route : this.router.url,
		};
		this.navigationHistoryService.clearNavigationHistory();
		this.navigationHistoryService.addNavigationHistory(pageTitle);
	}

	private setTabsNotificationIcon() {
		this.tabsService.tabNotifications$.subscribe(res => {
			this.store
				.select(selectTabs)
				.pipe(take(1))
				.subscribe(loadedTabs => {
					if (loadedTabs) {
						const tab = loadedTabs.find(tab => tab.name === res.tabName);
						if (tab) {
							tab.notificationsCount = res.value;
							this.store.dispatch(new TabsLoaded({ tabs: loadedTabs }));
						}
					}
				});
		});
	}

	private setCodeChangeUtilityPageTitle(
		name: string,
		entityId: number,
		data: CustomRouteData,
		route: string,
		entityType: EntityType
	): void {
		route = route + '/' + entityId;
		this.setNavigationHistory(name, entityId, data, route, entityType, true);
	}

	private setNoEntityPageTitle(data: CustomRouteData): void {
		this.setNavigationHistory(data.pageTitle, null, data, data.route, data.entityType, true);
	}
}
