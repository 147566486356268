import { ReviewAction } from '../../enums/review-action.enum';
import { IngredientApproval } from '../../enums/ingredient-approval-status.enum';
import { BatchIngredientItem } from './batch-ingredient.model';
import { MasterIngredientExtended } from './master-ingredient.model';

export class IngredientBatchReviewAction {
	action: ReviewAction;
	approvalStatus?: IngredientApproval;
	batchIngredient?: BatchIngredientItem;
	masterIngredient?: MasterIngredientExtended;
}
