export enum TaskEntityTypeId {
	Application = 1,
	BusinessEntity = 3,
	Company = 4,
	Facility = 5,
	Person = 7,
	ContactDetail = 9,
	Workflow = 10,
	Product = 11,
	ProductEvaluation = 13,
	KosherCertificate = 14,
	IngredientInfo = 15,
	KosherEvaluation = 16,
	K_ID = 17,
	Visit = 18,
	Agency = 25,
	PrivateLabeler = 26,
	Vendor = 27,
	BatchIngredient = 28,
	FormulaImport = 30,
	VisitForm = 55,
	PaperSubmission = 56,
	Communication = 57,
	CommActivity = 58,
	Project = 59,
	Manufacturer = 77,
	ProductBatch = 83,
	Trip = 97,
}
