import { Component } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-refresh',
	templateUrl: './icon-refresh.component.html',
	styleUrls: ['./icon-refresh.component.scss'],
})
export class IconRefreshComponent extends BaseIcon {
	constructor() {
		super();
	}
}
