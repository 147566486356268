import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-settings',
	templateUrl: './icon-settings.component.html',
	styleUrls: ['./icon-settings.component.scss'],
})
export class IconSettingsComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
