export enum LocalStorageKeys {
	LOGIN_DATA_STORAGE_KEY = 'lgn',
	ACTIVE_MODE_STORAGE_KEY = 'am',
	USER_ID_KEY = 'uid',
	CURRENT_USER = 'cusr',
	VIEW_MODE_KEY = 'vm',
	ADMIN_MAGIC_REVIEW_PAGE_IDS = 'mrpids',
	ADMIN_PRODUCT_REVIEW_PAGE_IDS = 'aprpids',
	ADMIN_RENEWAL_PAGE_IDS = 'rpids',
	USER_PERMISSIONS = 'up',
	USER_ACCORDIONS_STATUS = 'accsts',
	USER_IDLE = 'idle',
	LAST_TAB_CLOSED_AT = 'ltca',
	ANALYZE_PRODUCT_IDS = 'apids',
	UPDATE_MASTER_INGREDIENT_IDS = 'umiids',
	USER_ADVANCED_PERMISSIONS = 'uap',
}
