import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FieldErrors } from './field-errors.model';

@Component({
	selector: 'app-input-error-messages',
	templateUrl: './input-error-messages.component.html',
	styleUrls: ['./input-error-messages.component.scss'],
})
export class InputErrorMessagesComponent {
	constructor() {}

	@Input() field: AbstractControl;
	@Input() showFieldErrors: boolean;

	get errorsObject(): FieldErrors {
		return this.field.errors;
	}
}
