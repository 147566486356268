import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ActiveModeEffects } from './effects/active-mode.effects';
import { ApplicationEffects } from './effects/application.effects';
import { AuthEffects } from './effects/auth.effects';
import { GlobalEffects } from './effects/global.effects';
import { MagicReviewPageEffects } from './effects/magic-review-page.effects';
import { SideInfoEffects } from './effects/sideinfo.effects';
import { SideNavEffects } from './effects/sidenav.effects';
import { TabsEffects } from './effects/tabs.effects';
import { UsersEffects } from './effects/users.effects';
import * as fromActiveMode from './reducers/active-mode.reducer';
import * as fromApplication from './reducers/application.reducer';
import * as fromAuth from './reducers/auth.reducer';
import * as fromGlobal from './reducers/global.reducer';
import * as magicReviewPage from './reducers/magic-review-page.reducer';
import * as fromSidenav from './reducers/sidenav.reducer';
import * as fromTabs from './reducers/tabs.reducer';
import * as fromUsers from './reducers/users.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('activeMode', fromActiveMode.activeModeReducer),
		StoreModule.forFeature('sidenav', fromSidenav.sidenavReducer),
		StoreModule.forFeature('tabs', fromTabs.tabsReducer),
		StoreModule.forFeature('application', fromApplication.applicationReducer),
		StoreModule.forFeature('global', fromGlobal.globalReducer),
		StoreModule.forFeature('users', fromUsers.usersReducer),
		StoreModule.forFeature('magicReviewPage', magicReviewPage.magicReviewPageReducer),
		StoreModule.forFeature('auth', fromAuth.authReducer),
		EffectsModule.forFeature([
			SideNavEffects,
			TabsEffects,
			SideInfoEffects,
			ActiveModeEffects,
			ApplicationEffects,
			GlobalEffects,
			UsersEffects,
			MagicReviewPageEffects,
			AuthEffects,
		]),
	],
})
export class AppStoreModule {}
