export enum AccordionIdentifier {
	// Ingredient Batch Page
	IngredientBatchInfo,
	// Magic Review Page
	ReviewIngredientInfo,
	ReviewIngredientInfoNotes,
	ReviewIngredientMasterIngredient,
	ReviewIngredientMasterIngredientNotes,
	ReviewIngredientCommunication,
	ReviewIngredientVendorProfile,
	ReviewIngredientFacilities,
	ReviewIngredientGuidelines,
	// Master ingredient info page
	MasterIngredientGeneralInfo,
	MasterIngredientKosherInfo,
	MasterIngredientEvaluation,
	MasterIngredientPackaging,
	MasterIngredientCertificate,
	// Edit batch ingredient item page
	BatchItemInfo,
	BatchItemMasterIngredient,
	BatchCustomerIngredientNotes,
	BatchItemPackaging,
	BatchItemNotes,
	BatchItemCommunication,
	BatchItemIngredientFacilities,
	// Permissions Tab
	GeneralPermissions,
	AdvancedPermissions,
	ProductReviewPageProductEvaluation,
	UnapproveProductEvaluations,
	InternalProductsRequiringApproval,
	ProductFormulaReport,
	// Facility Contract
	FacilityContracts,
	ContractDetails,
	VisitFees,
	FieldRepTerms,
}
