export enum NoteType {
	All = -1,
	Comments = 1,
	FeeComments = 2,
	GeneralNotes = 3,
	Directions = 4,
	KosherLetterNote = 5,
	History = 6,
	KCGuideline = 7,
	KosherEvaluationNote = 8,
	ThankYouNote = 9,
	FormulaGuideline = 10,
	ProductNotes = 11,
	KCNote = 12,
	IngredientGuideline = 13,
	AccountingPayNote = 18,
	CommunicationActivity = 19,
	KashrusInstructions = 20,
	BatchProductItem = 21,
	Instruction = 22,
	InternalNote = 23,
	InternalNoteToRabbi = 24,
	PublicNote = 25,
	CustomerNote = 26,
	IncreaseAnnualFeeNote = 27,
	TravelNotes = 28,
	AccommodationNotes = 29,
	VisitNote = 30,
	RenewalNote = 31,
	KosherCertificateNote = 32,
	SpecialArrangementNote = 33,
	VisitReportQuestionNote = 34,
	PublicVoidNote = 35,
	OnHold = 36,
	TripExpense = 37,
}
