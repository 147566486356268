import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-info',
	templateUrl: './icon-info.component.html',
	styleUrls: ['./icon-info.component.scss'],
})
export class IconInfoComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
