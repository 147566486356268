import { UserModuleTabs } from '../../enums/permission-module.enum';

export const userSettingInfoTabs = [
	{
		name: 'Info',
		path: `/user-settings/:id`,
		permissionId: UserModuleTabs.Info,
	},
	{
		name: 'Security',
		path: `/user-settings/:id/security`,
		permissionId: UserModuleTabs.Security,
	},
	{
		name: 'Restrictions',
		path: `/user-settings/:id/restrictions`,
		permissionId: UserModuleTabs.Restrictions,
	},
	{
		name: 'Tasks',
		path: `/user-settings/:id/tasks`,
		permissionId: UserModuleTabs.Tasks,
	},
];
