import { Action } from '@ngrx/store';
import { LoginResponse } from 'src/app/core/models/auth/login.response';

export enum AuthActionTypes {
	LoginAction = '[Login] Action',
	LogoutAction = '[Logout] Action',
}

export class Login implements Action {
	readonly type = AuthActionTypes.LoginAction;

	constructor(public payload: { loginResponse: LoginResponse }) {}
}

export class Logout implements Action {
	readonly type = AuthActionTypes.LogoutAction;
}

export type AuthActions = Login | Logout;
