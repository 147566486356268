import { PermissionModule } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const visitModuleTabs: Tab[] = [
	{
		name: 'Visits',
		path: `/visits`,
		isModule: true,
		permissionId: PermissionModule.Visits,
	},
	{
		name: 'Trips',
		path: `/trips`,
		isModule: true,
		permissionId: PermissionModule.Trips,
	},
	{
		name: 'Amex',
		path: `/amex`,
		isModule: true,
		permissionId: PermissionModule.Amex,
	},
];
