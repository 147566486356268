import { NgModule } from '@angular/core';

import { DropdownDirective } from './dropdown/dropdown.directive';
import { DropdownToggleDirective } from './dropdown/dropdown-toggle.directive';
import { DropdownMenuDirective } from './dropdown/dropdown-menu.directive';
import { AccordionToggleDirective } from './accordion/accordion-toggle.directive';
import { AccordionContentDirective } from './accordion/accordion-content.directive';

@NgModule({
	imports: [],
	exports: [
		DropdownDirective,
		DropdownToggleDirective,
		DropdownMenuDirective,
		AccordionToggleDirective,
		AccordionContentDirective,
	],
	declarations: [
		DropdownDirective,
		DropdownToggleDirective,
		DropdownMenuDirective,
		AccordionToggleDirective,
		AccordionContentDirective,
	],
	providers: [AccordionContentDirective],
})
export class CustomDirectivesModule {}
