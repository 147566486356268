import { CategoryHeader } from '../enums/category-header.enum';
import { EntityAttributeType } from '../enums/entity-attribute-type.enum';

export class BaseModel {
	id: any;
	name: string;

	// UI
	selected?: boolean;
}

export class BaseIndexModel extends BaseModel {
	abbreviation: string;
	allowEdit: boolean;
	description: string;
	detail: string;
	hasCustomValue: boolean;
	sortOrder: number;
}

export class BaseModelCategory extends BaseIndexModel {
	categoryHeaderId: CategoryHeader;
}

export class BaseModelRole extends BaseIndexModel {
	businessEntityRoleId: number;
}

export class BaseModelInvoicesGroups extends BaseModel {
	relatedEntityId?: number;
	invoicingGroupId?: number;
}

export class OwnerAutocomplete extends BaseModel {
	entityTypeId: number;
	expirationDate?: Date | string;
	expirationDateFormat?: Date | string;
}

export class JobModel {
	id: any;
	name: string;
	main: boolean;
	isOther: boolean;
}

export class CountryModel extends BaseIndexModel {
	continent: string;
	continentId: number;
	continentRegionId: number;
	regex: string;
}

export class VisitStatusBaseModel extends BaseModel {
	allowEditExpenses: boolean;
	allowSendForProcessing: boolean;
	allowReopen: boolean;
}

export class FindBaseModel extends BaseModel {
	active: boolean;
	disabled: boolean;
	displayName: string;
}

export class ColorBaseModel extends BaseModel {
	color: string;
}

export class BaseModelEvaluationGroup extends BaseIndexModel {
	evaluationGroupId: number;
	value: string;
	attributeId: EntityAttributeType;
}
