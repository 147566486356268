import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	getHebrewDate(date: Date | string) {
		return this.http.get(`${this.appConfigurationService.api.settings}/configuration/hebrew?date=${date}`);
	}

	getServerUtcDate(): Observable<{ utcDate: string }> {
		return this.http.get<{ utcDate: string }>(
			`${this.appConfigurationService.api.settings}/configuration/currentUtcDate`
		);
	}
}
