import { ApplicationDetail, ApplicationBusinessEntity, ApplicationFacility } from '../../core/models/application.model';
import { ApplicationActions, ApplicationActionTypes } from '../actions/application.actions';

export interface ApplicationState {
	applicationId: number;
	appSummary: ApplicationDetail;
	appSummaryLoading: boolean;
	appSummarySaved: boolean;
	applicationPublished: boolean;
	appFacilitiesEntities: ApplicationBusinessEntity[];
	appFacilities: ApplicationFacility[];
	appFacilitiesSaveResult: ApplicationFacility;
	loadingAppFacilites: boolean;
	appFacilitiesSaved: boolean;
}

export const initialState: ApplicationState = {
	applicationId: undefined,
	appSummary: undefined,
	appSummaryLoading: false,
	appSummarySaved: undefined,
	applicationPublished: false,
	appFacilitiesEntities: [],
	appFacilities: [],
	appFacilitiesSaveResult: null,
	loadingAppFacilites: false,
	appFacilitiesSaved: undefined,
};

export function applicationReducer(state = initialState, action: ApplicationActions): ApplicationState {
	switch (action.type) {
		case ApplicationActionTypes.ApplicationSummaryLoaded:
			return {
				...state,
				appSummary: action.payload.appSummary,
			};

		case ApplicationActionTypes.ApplicationSummaryLoading:
			return {
				...state,
				appSummaryLoading: action.payload.appSummaryLoading,
			};

		case ApplicationActionTypes.ApplicationSummarySaveReferral:
			const newAppSummary = { ...state.appSummary, ...action.payload.referralInfo };
			newAppSummary.referralFees = action.payload.referralFees || state.appSummary.referralFees;

			return {
				...state,
				appSummary: newAppSummary,
				appSummarySaved: false,
			};

		case ApplicationActionTypes.ApplicationSummarySaveGeneral:
			const newAppSummaryGeneral = { ...state.appSummary, ...action.payload.generalInfo };
			return {
				...state,
				appSummary: newAppSummaryGeneral,
				appSummarySaved: false,
			};

		case ApplicationActionTypes.ApplicationSummarySaveSuccess:
			return {
				...state,
				appSummarySaved: true,
			};

		case ApplicationActionTypes.ApplicationPublishUpdate:
			return {
				...state,
				applicationPublished: action.payload.applicationPublished,
			};

		case ApplicationActionTypes.ApplicationFacilitiesLoad:
			return {
				...state,
				loadingAppFacilites: true,
			};
		case ApplicationActionTypes.ApplicationFacilitiesLoaded:
			const hasActiveFacility = action.payload.appFacilities.find(f => f.isActive);
			if (!hasActiveFacility && action.payload.appFacilities.length) {
				action.payload.appFacilities[0].isActive = true;
			}

			return {
				...state,
				appFacilities: action.payload.appFacilities,
				loadingAppFacilites: false,
			};
		case ApplicationActionTypes.ApplicationFacilitiesSave:
			return {
				...state,
				loadingAppFacilites: true,
				appFacilitiesSaved: false,
			};
		case ApplicationActionTypes.ApplicationFacilitiesSaveSuccess:
			let newFacilitesCopy = state.appFacilities;
			let newFacilites = [];
			const { facility } = action.payload;
			let updated = false;
			newFacilitesCopy.forEach((f, idx) => {
				if (f.info.id === facility.info.id) {
					newFacilitesCopy[idx] = facility;
					newFacilitesCopy[idx].isActive = true;
					updated = true;
				}
			});

			if (!updated) {
				newFacilitesCopy.forEach(f => (f.isActive = false));
				if (facility.info.id > 0) {
					newFacilitesCopy = newFacilitesCopy.filter(f => f.info.id > 0);
					facility.isActive = true;
				}
				newFacilitesCopy.push(facility);
			}

			newFacilites = newFacilitesCopy;

			return {
				...state,
				appFacilities: newFacilites,
				loadingAppFacilites: false,
				appFacilitiesSaved: true,
			};
		case ApplicationActionTypes.ApplicationFacilitySelect:
			return {
				...state,
				appFacilities: state.appFacilities.map(f => ({
					...f,
					isActive: f.info.id === action.payload.facility.info.id,
				})),
			};

		case ApplicationActionTypes.ApplicationFacilitySaveResult:
			return { ...state, appFacilitiesSaveResult: action.payload.facility };
		case ApplicationActionTypes.ApplicationCleanUp:
			return { ...initialState };
		default:
			return state;
	}
}
