import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoute } from './core/interfaces/custom-routes.interface';
import { AuthGuard } from './shared/route-guards/auth.guard';
import { UserPermissionGuard } from './shared/route-guards/user-permission.route-guard';

const routes: CustomRoute[] = [
	{
		path: '',
		loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
	},
	{
		path: '',
		loadChildren: () =>
			import('./modules/admin-master-page/admin-master-page.module').then(m => m.AdminMasterPageModule),
		canActivate: [AuthGuard],
		canActivateChild: [UserPermissionGuard],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			relativeLinkResolution: 'legacy',
			paramsInheritanceStrategy: 'always',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
