import { Injectable } from '@angular/core';
import { MenuItem } from '../models/menu-item.model';
import { of, Observable } from 'rxjs';
import { IconCompaniesComponent } from 'src/app/shared/icons/icon-companies/icon-companies.component';
import { IconFacilitiesComponent } from 'src/app/shared/icons/icon-facilities/icon-facilities.component';
import { IconPrivateLabelComponent } from 'src/app/shared/icons/icon-private-label/icon-private-label.component';
import { IconVendorComponent } from 'src/app/shared/icons/icon-vendor/icon-vendor.component';
import { IconAgencyComponent } from 'src/app/shared/icons/icon-agency/icon-agency.component';
import { IconIngredientComponent } from 'src/app/shared/icons/icon-ingredient/icon-ingredient.component';
import { IconMasterIngredientComponent } from 'src/app/shared/icons/icon-master-ingredient/icon-master-ingredient.component';
import { IconPeopleComponent } from 'src/app/shared/icons/icon-people/icon-people.component';
import { IconVisitsComponent } from 'src/app/shared/icons/icon-visits/icon-visits.component';
import { IconSettingsComponent } from 'src/app/shared/icons/icon-settings/icon-settings.component';
import { IconTasksComponent } from 'src/app/shared/icons/icon-tasks/icon-tasks.component';
import { IconDashboardComponent } from '../../shared/icons/icon-dashboard/icon-dashboard.component';
import { PermissionModule } from '../enums/permission-module.enum';
import { ModuleType } from '../enums/module-type.enum';

@Injectable({
	providedIn: 'root',
})
export class SidenavService {
	constructor() {}

	getMenuItems(): Observable<MenuItem[]> {
		return of([
			{
				id: ModuleType.Dashboard,
				name: 'Dashboard',
				icon: IconDashboardComponent,
				path: '/dashboard',
				activeOnSubRoutes: ['/dashboard'],
			},
			{
				id: ModuleType.Company,
				name: 'Companies',
				icon: IconCompaniesComponent,
				path: '/companies',
				permissionIds: [PermissionModule.Company, PermissionModule.AccountingRenewal],
				activeOnSubRoutes: ['/companies/**'],
			},
			{
				id: ModuleType.Facility,
				name: 'Projects',
				icon: IconFacilitiesComponent,
				path: '/projects',
				permissionIds: [PermissionModule.Facility],
				activeOnSubRoutes: ['/projects/**'],
			},
			// {
			// 	id: ModuleType.PrivateLabel,
			// 	name: 'Private Label',
			// 	icon: IconPrivateLabelComponent,
			// 	path: '/private-label',
			// 	permissionIds: [PermissionModule.PrivateLabeler],
			// 	activeOnSubRoutes: ['/private-label/**'],
			// },
			// {
			// 	id: ModuleType.Vendors,
			// 	name: 'Vendors',
			// 	icon: IconVendorComponent,
			// 	permissionIds: [PermissionModule.Vendor],
			// 	path: '/vendors',
			// 	activeOnSubRoutes: ['/vendors/**'],
			// },
			// {
			// 	id: ModuleType.Agencies,
			// 	name: 'Agencies',
			// 	icon: IconAgencyComponent,
			// 	path: '/agencies',
			// 	permissionIds: [PermissionModule.Agency],
			// 	activeOnSubRoutes: ['/agencies/**'],
			// },
			// {
			// 	id: ModuleType.CustomerIngredient,
			// 	name: 'Customer Ingredient',
			// 	permissionIds: [
			// 		PermissionModule.CustomerIngredients,
			// 		PermissionModule.IngredientBatch,
			// 		PermissionModule.ReviewIngredients,
			// 	],
			// 	icon: IconIngredientComponent,
			// 	path: '/ingredients/customer-ingredient',
			// 	activeOnSubRoutes: ['/ingredients/**'],
			// },
			// {
			// 	id: ModuleType.MasterIngredient,
			// 	name: 'Master Ingredient',
			// 	permissionIds: [PermissionModule.MasterIngredient, PermissionModule.ChangedMI],
			// 	icon: IconMasterIngredientComponent,
			// 	path: '/master-ingredient',
			// 	activeOnSubRoutes: ['/master-ingredient/:id/**', '/submit/master-ingredient'],
			// },
			{
				id: ModuleType.Contacts,
				name: 'Contacts',
				icon: IconPeopleComponent,
				path: '/contacts',
				permissionIds: [PermissionModule.Contact],
				activeOnSubRoutes: ['/contacts/:id**'],
			},
			// {
			// 	id: ModuleType.Visits,
			// 	name: 'Visits',
			// 	icon: IconVisitsComponent,
			// 	path: '/visits',
			// 	permissionIds: [PermissionModule.Visits, PermissionModule.Trips, PermissionModule.Amex],
			// 	activeOnSubRoutes: ['/visits/**', '/trips/**', '/amex/**'],
			// },
			{
				id: ModuleType.Tasks,
				name: 'Tasks',
				icon: IconTasksComponent,
				path: '/tasks',
				permissionIds: [PermissionModule.Task],
				activeOnSubRoutes: ['/tasks/:id/**'],
			},
			{
				id: ModuleType.Settings,
				name: 'Settings',
				icon: IconSettingsComponent,
				permissionIds: [
					PermissionModule.Users,
					PermissionModule.Roles,
					PermissionModule.Groups,
					PermissionModule.Permissions,
					PermissionModule.ProfileTemplates,
					PermissionModule.DocumentTemplates,
					PermissionModule.RulesEngine,
					PermissionModule.Scripts,
					PermissionModule.Categories,
				],
				path: '/settings/users',
				activeOnSubRoutes: ['/settings/**', '/user-settings/**'],
			},
		]);
	}
}
