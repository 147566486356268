import { MenuItem } from '../../core/models/menu-item.model';
import { SidenavActions, SidenavActionTypes } from '../actions/sidenav.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface SidenavState extends EntityState<MenuItem> {
	menuItems: MenuItem[];
}

export const adapter: EntityAdapter<MenuItem> = createEntityAdapter<MenuItem>();

export const initialSidenavState: SidenavState = adapter.getInitialState(undefined);

export function sidenavReducer(state = initialSidenavState, action: SidenavActions): SidenavState {
	switch (action.type) {
		case SidenavActionTypes.MenuItemsLoaded:
			return adapter.setAll(action.payload.menuItems, { ...state });
		default:
			return state;
	}
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
