import { PermissionModule } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const taskModuleTabs: Tab[] = [
	{
		name: 'Info',
		path: `/tasks/:id`,
		permissionId: PermissionModule.Task,
		isModule: true,
	}
];
