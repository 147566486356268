import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/store/actions/auth.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
	constructor(private store: Store<AppState>) {}

	ngOnInit(): void {
		this.store.dispatch(new Logout());
	}
}
