import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-facilities',
	templateUrl: './icon-facilities.component.html',
	styleUrls: ['./icon-facilities.component.scss'],
})
export class IconFacilitiesComponent extends BaseIcon implements OnInit {
	constructor() {
        super()
    }

	ngOnInit() {}
}
