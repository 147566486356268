import { AppRouteIdentifier } from '../../enums/app-route-identifier.enum';
import { appSpecialRoutes } from '../app-routes.const';

export const masterIngredientModuleTabs = [
	{
		name: 'Master Ingredients',
		path: appSpecialRoutes[AppRouteIdentifier.MasterIngredientList].path,
		isModule: appSpecialRoutes[AppRouteIdentifier.MasterIngredientList].isModule,
		permissionId: appSpecialRoutes[AppRouteIdentifier.MasterIngredientList].permissionId,
	},
	{
		name: 'Changed MI',
		path: appSpecialRoutes[AppRouteIdentifier.ChangedMasterIngredient].path,
		isModule: appSpecialRoutes[AppRouteIdentifier.ChangedMasterIngredient].isModule,
		permissionId: appSpecialRoutes[AppRouteIdentifier.ChangedMasterIngredient].permissionId,
	},
];
