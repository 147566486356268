import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CustomRouteData } from 'src/app/core/interfaces/custom-routes.interface';
import { PermissionService } from 'src/app/core/services/communication/permission.service';

@Injectable()
export class UserPermissionGuard implements CanActivateChild {
	constructor(private permissionService: PermissionService, private router: Router) {}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		const routeData = route.data as CustomRouteData;
		const canActivate = this.permissionService.canView([routeData.permissionId], routeData.isModule);
		if (!canActivate) {
			return this.router.parseUrl('forbidden');
		}
		return canActivate;
	}
}
