export enum ModuleType {
	Dashboard,
	UKDSearch,
	Company,
	Facility,
	PrivateLabel,
	Vendors,
	Agencies,
	CustomerIngredient,
	MasterIngredient,
	Contacts,
	Visits,
	Tasks,
	Settings,
}
