import { Action } from '@ngrx/store';
import { MenuItem } from '../../core/models/menu-item.model';
import { Tab } from '../../core/models/tab.model';
import { TabsType } from '../../core/enums/tabs-type.enum';

export enum TabsActionTypes {
	TabsRequested = '[Global] Tabs requested',
	TabsLoaded = '[Global] Tabs loaded',
	EntityUpdate = '[Global] Entity updated',
}

export class TabsRequested implements Action {
	readonly type = TabsActionTypes.TabsRequested;

	constructor(public payload: { type: TabsType }) {}
}

export class TabsLoaded implements Action {
	readonly type = TabsActionTypes.TabsLoaded;

	constructor(public payload: { tabs: Tab[] }) {}
}

export class EntityUpdate implements Action {
	readonly type = TabsActionTypes.EntityUpdate;

	constructor(public payload: { entityId?: number; companyId?: number; facilityId?: number; agreementId?: number }) {}
}

export type TabsActions = TabsRequested | TabsLoaded | EntityUpdate;
