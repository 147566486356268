import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import { SidenavService } from '../../core/services/sidenav.service';
import { MenuItemsLoaded, MenuItemsRequested, SidenavActionTypes } from '../actions/sidenav.actions';
import { AppState } from '../reducers';

@Injectable()
export class SideNavEffects {
	@Effect()
	loadAllCourses$ = this.actions$.pipe(
		ofType<MenuItemsRequested>(SidenavActionTypes.MenuItemsRequested),
		mergeMap(() => this.sidenavService.getMenuItems()),
		map(menuItems => new MenuItemsLoaded({ menuItems }))
	);

	constructor(private actions$: Actions, private sidenavService: SidenavService, private store: Store<AppState>) {}
}
