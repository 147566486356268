import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconAgencyComponent } from './icon-agency/icon-agency.component';
import { IconAlertsComponent } from './icon-alerts/icon-alerts.component';
import { IconArrowComponent } from './icon-arrow/icon-arrow.component';
import { IconAttachmentComponent } from './icon-attachment/icon-attachment.component';
import { IconCalendarComponent } from './icon-calendar/icon-calendar.component';
import { IconCardComponent } from './icon-card/icon-card.component';
import { IconCertificateComponent } from './icon-certificate/icon-certificate.component';
import { IconCheckInverseComponent } from './icon-check-inverse/icon-check-inverse.component';
import { IconCheckMultipleComponent } from './icon-check-multiple/icon-check-multiple.component';
import { IconCheckComponent } from './icon-check/icon-check.component';
import { IconClockComponent } from './icon-clock/icon-clock.component';
import { IconCloseComponent } from './icon-close/icon-close.component';
import { IconCompaniesComponent } from './icon-companies/icon-companies.component';
import { IconCopyComponent } from './icon-copy/icon-copy.component';
import { IconCreateComponent } from './icon-create/icon-create.component';
import { IconDashboardComponent } from './icon-dashboard/icon-dashboard.component';
import { IconDeleteComponent } from './icon-delete/icon-delete.component';
import { IconDolarSignComponent } from './icon-dolar-sign/icon-dolar-sign.component';
import { IconDownloadComponent } from './icon-download/icon-download.component';
import { IconEarthComponent } from './icon-earth/icon-earth.component';
import { IconEditBlockComponent } from './icon-edit-block/icon-edit-block.component';
import { IconEditComponent } from './icon-edit/icon-edit.component';
import { IconEmailOpenedComponent } from './icon-email-opened/icon-email-opened.component';
import { IconEmailComponent } from './icon-email/icon-email.component';
import { IconFacilitiesComponent } from './icon-facilities/icon-facilities.component';
import { IconFileComponent } from './icon-file/icon-file.component';
import { IconInactiveComponent } from './icon-inactive/icon-inactive.component';
import { IconInfoComponent } from './icon-info/icon-info.component';
import { IconIngredientComponent } from './icon-ingredient/icon-ingredient.component';
import { IconLinkComponent } from './icon-link/icon-link.component';
import { IconLinksComponent } from './icon-links/icon-links.component';
import { IconLogoutComponent } from './icon-logout/icon-logout.component';
import { IconMapComponent } from './icon-map/icon-map.component';
import { IconMasterIngredientComponent } from './icon-master-ingredient/icon-master-ingredient.component';
import { IconMergeComponent } from './icon-merge/icon-merge.component';
import { IconMoveComponent } from './icon-move/icon-move.component';
import { IconNewTabComponent } from './icon-new-tab/icon-new-tab.component';
import { IconNoResultComponent } from './icon-no-result/icon-no-result.component';
import { IconNoResultsComponent } from './icon-no-results/icon-no-results.component';
import { IconPdfLinkComponent } from './icon-pdf-link/icon-pdf-link.component';
import { IconPeopleComponent } from './icon-people/icon-people.component';
import { IconPhoneComponent } from './icon-phone/icon-phone.component';
import { IconPlaneComponent } from './icon-plane/icon-plane.component';
import { IconPoComponent } from './icon-po/icon-po.component';
import { IconPrintComponent } from './icon-print/icon-print.component';
import { IconPrivateLabelComponent } from './icon-private-label/icon-private-label.component';
import { IconProductsComponent } from './icon-products/icon-products.component';
import { IconRefreshComponent } from './icon-refresh/icon-refresh.component';
import { IconScissorComponent } from './icon-scissor/icon-scissor.component';
import { IconSearchComponent } from './icon-search/icon-search.component';
import { IconSendComponent } from './icon-send/icon-send.component';
import { IconSettingsComponent } from './icon-settings/icon-settings.component';
import { IconSyncComponent } from './icon-sync/icon-sync.component';
import { IconTasksComponent } from './icon-tasks/icon-tasks.component';
import { IconTitleArrowComponent } from './icon-title-arrow/icon-title-arrow.component';
import { IconUnlinksComponent } from './icon-unlinks/icon-unlinks.component';
import { IconUserSettingsComponent } from './icon-user-settings/icon-user-settings.component';
import { IconVatComponent } from './icon-vat/icon-vat.component';
import { IconVendorComponent } from './icon-vendor/icon-vendor.component';
import { IconViewMoreComponent } from './icon-view-more/icon-view-more.component';
import { IconViewComponent } from './icon-view/icon-view.component';
import { IconVisitsComponent } from './icon-visits/icon-visits.component';
import { IconWarningComponent } from './icon-warning/icon-warning.component';

@NgModule({
	imports: [CommonModule],
	declarations: [
		IconCompaniesComponent,
		IconFacilitiesComponent,
		IconPrivateLabelComponent,
		IconVendorComponent,
		IconAgencyComponent,
		IconProductsComponent,
		IconCertificateComponent,
		IconIngredientComponent,
		IconMasterIngredientComponent,
		IconPeopleComponent,
		IconVisitsComponent,
		IconPrintComponent,
		IconDownloadComponent,
		IconLogoutComponent,
		IconSettingsComponent,
		IconEditComponent,
		IconInfoComponent,
		IconAlertsComponent,
		IconCheckComponent,
		IconCloseComponent,
		IconInactiveComponent,
		IconCheckMultipleComponent,
		IconEditBlockComponent,
		IconSearchComponent,
		IconNoResultsComponent,
		IconMapComponent,
		IconWarningComponent,
		IconDeleteComponent,
		IconEmailComponent,
		IconEmailOpenedComponent,
		IconPhoneComponent,
		IconCheckInverseComponent,
		IconLinkComponent,
		IconFileComponent,
		IconUserSettingsComponent,
		IconCalendarComponent,
		IconPlaneComponent,
		IconEarthComponent,
		IconClockComponent,
		IconMergeComponent,
		IconCreateComponent,
		IconNewTabComponent,
		IconPdfLinkComponent,
		IconViewMoreComponent,
		IconLinksComponent,
		IconArrowComponent,
		IconCardComponent,
		IconNoResultComponent,
		IconTasksComponent,
		IconNoResultComponent,
		IconSendComponent,
		IconAttachmentComponent,
		IconDolarSignComponent,
		IconUnlinksComponent,
		IconViewComponent,
		IconMoveComponent,
		IconRefreshComponent,
		IconScissorComponent,
		IconVatComponent,
		IconPoComponent,
		IconDashboardComponent,
		IconCopyComponent,
		IconTitleArrowComponent,
		IconSyncComponent,
	],
	exports: [
		IconCompaniesComponent,
		IconFacilitiesComponent,
		IconPrivateLabelComponent,
		IconVendorComponent,
		IconAgencyComponent,
		IconProductsComponent,
		IconCertificateComponent,
		IconIngredientComponent,
		IconMasterIngredientComponent,
		IconPeopleComponent,
		IconVisitsComponent,
		IconPrintComponent,
		IconDownloadComponent,
		IconLogoutComponent,
		IconSettingsComponent,
		IconEditComponent,
		IconInfoComponent,
		IconAlertsComponent,
		IconCheckComponent,
		IconCloseComponent,
		IconInactiveComponent,
		IconCheckMultipleComponent,
		IconEditBlockComponent,
		IconSearchComponent,
		IconNoResultsComponent,
		IconMapComponent,
		IconWarningComponent,
		IconDeleteComponent,
		IconEmailComponent,
		IconEmailOpenedComponent,
		IconPhoneComponent,
		IconCheckInverseComponent,
		IconLinkComponent,
		IconFileComponent,
		IconUserSettingsComponent,
		IconCalendarComponent,
		IconPlaneComponent,
		IconEarthComponent,
		IconClockComponent,
		IconMergeComponent,
		IconCreateComponent,
		IconNewTabComponent,
		IconPdfLinkComponent,
		IconViewMoreComponent,
		IconLinksComponent,
		IconArrowComponent,
		IconCardComponent,
		IconNoResultComponent,
		IconTasksComponent,
		IconNoResultComponent,
		IconSendComponent,
		IconAttachmentComponent,
		IconDolarSignComponent,
		IconUnlinksComponent,
		IconViewComponent,
		IconMoveComponent,
		IconRefreshComponent,
		IconScissorComponent,
		IconVatComponent,
		IconPoComponent,
		IconDashboardComponent,
		IconCopyComponent,
		IconTitleArrowComponent,
		IconSyncComponent,
	],
})
export class IconsModule {}
