import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TabsActions, TabsActionTypes } from '../actions/tabs.actions';
import { Tab } from '../../core/models/tab.model';

export interface TabsState extends EntityState<Tab> {
	menuItems: Tab[];
	entityId?: number;
	companyId?: number;
	facilityId?: number;
	agreementId?: number;
}

export const adapter: EntityAdapter<Tab> = createEntityAdapter<Tab>();

export const initialState: TabsState = adapter.getInitialState(undefined);

export function tabsReducer(state = initialState, action: TabsActions): TabsState {
	switch (action.type) {
		case TabsActionTypes.TabsLoaded:
			if (action.payload.tabs) {
				action.payload.tabs.forEach((tab, index) => {
					tab.id = index + 1;
					tab.display = false;
				});
			}
			return adapter.setAll(action.payload.tabs, { ...state });
		case TabsActionTypes.EntityUpdate:
			return {
				...state,
				entityId: action.payload.entityId ? action.payload.entityId : state.entityId,
				companyId: action.payload.companyId ? action.payload.companyId : state.companyId,
				facilityId: action.payload.facilityId ? action.payload.facilityId : state.facilityId,
				agreementId: action.payload.agreementId ? action.payload.agreementId : state.agreementId,
			};
		default:
			return state;
	}
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
